import {
  IconAmbulance,
  IconBox,
  IconDental,
  IconMassage,
  IconNeedle,
  IconPhoneCall,
  IconPill,
  IconReceipt2,
  IconScanEye,
  IconVaccine,
} from "@tabler/icons-react";

const RouteList = [
  { label: "home", route: "" },
  { label: "about", route: "about" },
  { label: "what we do", route: "us" },
  { label: "our services", route: "services" },
];

const DepartmentRouteList = [
  { route: "telemedicine", label: "Telemedicine", icon: IconPhoneCall },
  { route: "pharmacy", label: "Pharmacy", icon: IconPill },
  { route: "diagnostics", label: "Diagnostics", icon: IconScanEye },
  { route: "medical-supplies", label: "Medical Supplies", icon: IconBox },
  {
    route: "ambulance",
    label: "Ambulance Services",
    icon: IconAmbulance,
  },
  { route: "dentistry", label: "Dentistry", icon: IconDental },
  { route: "physiotherapy", label: "Physiotherapy", icon: IconMassage },
  { route: "phlembotomy", label: "Phlembotomy", icon: IconVaccine },
];

export { RouteList, DepartmentRouteList };
