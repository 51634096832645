import { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";

import { Container } from "@mui/material";
import { nprogress } from "@mantine/nprogress";

import {
  Card,
  PinInput,
  Text,
  TextInput,
  Button,
  TextInputProps,
  PasswordInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";

import { IconAt, IconKey } from "@tabler/icons-react";

import Cookies from "js-cookie";
import { CredentialResponse, GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import Logo from "../../Assets/IMG/Logo.svg";
import { Endpoints } from "../../Lib/Endpoints";
import {
  DefaultErrorNotification,
  DefaultSuccessNotification,
  validateEmail,
  validatePassword,
} from "../../Lib/Methods";
import { DefaultResponse } from "../../Lib/Responses";
import { PerformRequest } from "../../Lib/usePerformRequest";

import "./styles.scss";

export default function Login() {
  const navigate = useNavigate();
  useEffect(() => {
    nprogress.start();
    document.title = "Login - Quickhealth";
  }, []);

  useEffect(() => {
    nprogress.complete();
  });
  const loginForm = useForm({
    initialValues: {
      email: "",
      password: "",
      otp: "",
    },
    validate: {
      email: (value) =>
        /^\S+@\S+$/.test(value) ? null : "Please enter a valid email",
      password: (value) =>
        loginMethod === "otp"
          ? null
          : validatePassword(value)
          ? null
          : "Your password is at least 8 characters",
    },
  });
  const [loginMethod, setLoginMethod] = useState<"otp" | "password">(
    "password"
  );
  const [currentScreen, setCurrentScreen] = useState<"email" | "otp">("email");
  const [isRequestingOTP, setRequestingOTP] = useState<boolean>(false);
  const [isVerifyingOTP, setVerifyingOTP] = useState<boolean>(false);
  const [OTP, setOTP] = useState<string>("");
  const [OTPToken, setOTPToken] = useState<string>("");
  const RequestOTP = async () => {
    const { email } = loginForm.values;
    const isEmailValid = validateEmail(email);
    if (isEmailValid) {
      setRequestingOTP(true);
      const r: DefaultResponse = await PerformRequest({
        method: "POST",
        route: Endpoints.LoginWithOTP,
        data: { email },
      }).catch(() => {
        setRequestingOTP(false);
      });
      setRequestingOTP(false);
      if (r && r.data && r.data.statusCode === 200) {
        DefaultSuccessNotification("OTP sent to your email!");
        setCurrentScreen("otp");
        setOTPToken(r.data.data);
      } else if (r && r.data && r.data.statusCode === 404) {
        DefaultErrorNotification("User does not exist!");
      } else {
        DefaultErrorNotification("An error occurred!");
      }
    }
  };
  const VerifyOTP = async () => {
    setVerifyingOTP(true);
    const r: DefaultResponse = await PerformRequest({
      method: "POST",
      route: `${Endpoints.ValidateOTP}?token=${OTPToken}`,
      data: { otp: OTP },
    }).catch(() => {
      setVerifyingOTP(false);
    });
    setVerifyingOTP(false);
    if (r && r.data && r.data.statusCode === 200) {
      const hasPendingRoute = Cookies.get("hasPendingRoute");
      DefaultSuccessNotification("Logged in successfully!");
      navigate(hasPendingRoute ?? "/dashboard");
      Cookies.set("token", r.data.data);
      localStorage.setItem("token", r.data.data);
    }
  };

  const [isLoginWithPasswordLoading, setLoginWithPasswordLoading] =
    useState<boolean>(false);

  const LoginWithPassword = async () => {
    const { email, password } = loginForm.values;
    const { hasErrors } = loginForm.validate();
    if (hasErrors) {
      DefaultErrorNotification("Please fill form correctly!");
    } else {
      setLoginWithPasswordLoading(true);
      const r: DefaultResponse = await PerformRequest({
        method: "POST",
        data: { email, password, userType: "patient" },
        route: Endpoints.LoginWithPassword,
      }).catch(() => {
        setLoginWithPasswordLoading(false);
        DefaultErrorNotification("Could not login");
      });
      if (r && r.data && r.data.statusCode === 200) {
        const hasPendingRoute = Cookies.get("hasPendingRoute");
        DefaultSuccessNotification("Login successful!");
        Cookies.set("token", r.data.data);
        localStorage.setItem("token", r.data.data);
        navigate(hasPendingRoute ?? "/dashboard");
      }
      if (r && r.data && r.data.statusCode === 401) {
        DefaultErrorNotification("Invalid email and password");
      }
      setLoginWithPasswordLoading(false);
    }
  };

  const handleGoogleLoginSuccess = (reference: CredentialResponse) => {
    const userGoogleProfile = jwtDecode(reference?.credential ?? "");
  };
  const handleGoogleLoginError = () => {
    notifications.show({
      message: "Could not login with Google",
      title: "Google login error!",
      color: "red",
    });
  };
  const inputProps: TextInputProps = {
    style: { width: "100%" },
  };

  return (
    <Container maxWidth="lg">
      {loginMethod === "otp" ? (
        <div className="login-container flex-col align-center justify-center">
          <img src={Logo} alt="" />
          <br />
          <Card
            shadow="sm"
            padding="lg"
            radius="md"
            withBorder
            className="card"
          >
            <form
              className="login-form flex-col align-center justify-between"
              onSubmit={(e) => {
                e.preventDefault();
                RequestOTP();
              }}
            >
              <Text fw={500}>Sign In</Text>
              <br />

              {currentScreen === "email" ? (
                <>
                  <TextInput
                    type="email"
                    name="email"
                    placeholder="Email Address"
                    leftSection={<IconAt size={16} />}
                    disabled={isRequestingOTP}
                    {...loginForm.getInputProps("email")}
                    {...inputProps}
                  />
                  <br />

                  <Button
                    fullWidth
                    loading={isRequestingOTP}
                    size="compact-md"
                    style={{
                      fontSize: "14px",
                    }}
                    onClick={RequestOTP}
                  >
                    Request OTP
                  </Button>
                  <div className="flex-row align-center justify-center width-100">
                    <Text
                      my={10}
                      onClick={() => {
                        // notifications.show({ message: "Coming soon" });
                        setLoginMethod("password");
                      }}
                      fz="sm"
                      c="blue"
                      className="pointer"
                    >
                      Use Password
                    </Text>
                  </div>
                </>
              ) : (
                <>
                  <div className="flex-row width-100 align-start justify-start">
                    <Text size="sm" fw={500}>
                      {loginForm.values.email}
                    </Text>
                    &nbsp;
                    <Text size="sm" c="blue">
                      <span
                        className="pointer"
                        onClick={() => {
                          setCurrentScreen("email");
                          setOTP("");
                        }}
                      >
                        Not you?
                      </span>
                    </Text>
                  </div>
                  <br />
                  <PinInput
                    type={/^[0-9]*$/}
                    inputType="tel"
                    length={6}
                    size="md"
                    onChange={(e) => {
                      setOTP(e);
                    }}
                    inputMode="numeric"
                  />
                  <br />
                  <Button
                    type="button"
                    fullWidth
                    loading={isVerifyingOTP}
                    size="compact-md"
                    style={{
                      fontSize: "14px",
                    }}
                    onClick={VerifyOTP}
                  >
                    Continue
                  </Button>
                </>
              )}
            </form>

            <div className="flex-row width-100 justify-between">
              <Text size="sm">Don't have an account?</Text>
              <Text c="blue" size="sm">
                <Link to="/register" className="auth-link">
                  Sign Up
                </Link>
              </Text>
            </div>
          </Card>
          <br />
          {/* <GoogleLogin
            onSuccess={handleGoogleLoginSuccess}
            onError={handleGoogleLoginError}
          /> */}
        </div>
      ) : (
        <div className="login-container flex-col align-center justify-center">
          <img src={Logo} alt="" />
          <br />
          <Card
            shadow="sm"
            padding="lg"
            radius="md"
            withBorder
            className="card"
          >
            <form
              className="login-form flex-col align-center justify-between"
              onSubmit={(e) => {
                e.preventDefault();
                LoginWithPassword();
              }}
            >
              <Text fw={500}>Sign In</Text>
              <br />

              <>
                <TextInput
                  placeholder="Email Address"
                  leftSection={<IconAt size={16} />}
                  {...loginForm.getInputProps("email")}
                  {...inputProps}
                />
                <br />
                <PasswordInput
                  placeholder="Password"
                  leftSection={<IconKey size={16} />}
                  {...loginForm.getInputProps("password")}
                  style={{ width: "100%" }}
                />
                <br />

                <Button
                  fullWidth
                  loading={isLoginWithPasswordLoading}
                  size="compact-md"
                  style={{
                    fontSize: "14px",
                  }}
                  onClick={LoginWithPassword}
                >
                  Login
                </Button>

                <div className="flex-row align-center justify-center width-100">
                  <Text
                    my={10}
                    onClick={() => {
                      setLoginMethod("otp");
                    }}
                    fz="sm"
                    c="blue"
                    className="pointer"
                  >
                    Use OTP
                  </Text>
                </div>
              </>
            </form>

            <div className="flex-row width-100 justify-between">
              <Text size="sm">Don't have an account?</Text>
              <Text c="blue" size="sm">
                <Link to="/register" className="auth-link">
                  Sign Up
                </Link>
              </Text>
            </div>
          </Card>
          <br />
          {/* <GoogleLogin
            onSuccess={handleGoogleLoginSuccess}
            onError={handleGoogleLoginError}
          /> */}
          <Link to="/reset-password">
            <Text fz="sm" c="blue">
              Forgot Password?
            </Text>
          </Link>
        </div>
      )}
    </Container>
  );
}
