import { useContext, useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";

import { Container, Grid } from "@mui/material";
import { nprogress } from "@mantine/nprogress";

import {
  Select,
  Group,
  TextInput,
  Text,
  Button,
  NumberInput,
  Textarea,
} from "@mantine/core";

import {
  IconInfoCircle,
  IconAt,
  IconClock,
  IconUserShield,
} from "@tabler/icons-react";

import Logo from "../../Assets/IMG/Logo.svg";
import "./styles.scss";
import States from "../../Lib/States.json";

import { ProtectedRouteList } from "../../Lib/ProtectedRouteList";

import DashboardNav from "../DashboardNav";
import {
  DefaultErrorNotification,
  DefaultSuccessNotification,
  getFullDate,
  validateEmail,
} from "../../Lib/Methods";
import { AppContext } from "../../Context/AppContext";
import Loader from "../Misc/Loader";
import { useForm } from "@mantine/form";
import { PerformRequest } from "../../Lib/usePerformRequest";
import { Endpoints } from "../../Lib/Endpoints";
import { DefaultResponse } from "../../Lib/Responses";

export default function Account() {
  const context = useContext(AppContext);
  const navigate = useNavigate();
  useEffect(() => {
    nprogress.start();
    document.title = "Dashboard - Quickhealth";
  }, []);

  useEffect(() => {
    nprogress.complete();
  });

  const gridStatsProps = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 6,
  };
  const profileForm = useForm({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      gender: "",
      state: "",
      lga: "",
      area: "",
    },
    validate: {
      firstname: (value) =>
        value.length === 0 ? "Please enter a valid name" : null,
      lastname: (value) =>
        value.length === 0 ? "Please enter a valid name" : null,
      email: (value) =>
        !validateEmail(value) ? "Please enter a valid email address" : null,
      phone: (value) =>
        value.length < 10 ? "Please enter a valid email phone" : null,
      gender: (value) =>
        value.length === 0 ? "Please select your gender" : null,
      state: (value) =>
        value?.length === 0 ? "Please select your state" : null,
      lga: (value) => (value.length === 0 ? "Please select your lga" : null),
      area: (value) =>
        value.length === 0 ? "Please enter your address" : null,
    },
  });

  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (context && context.profile && !context.isLoadingProfile) {
      const { firstname, lastname, email, phone, gender, address } =
        context.profile;
      profileForm.setValues({
        firstname: firstname ?? "",
        lastname: lastname ?? "",
        email: email ?? "",
        phone: phone ?? "",
        gender: gender ?? "",
        state: address?.state ?? "",
        lga: address?.lga ?? "",
        area: address?.area ?? "",
      });
    }
  }, [context]);
  const states = States.map((state, index) => {
    return { value: state.alias, label: state.state };
  });
  const [lgaOptions, setLgaOptions] = useState<any[]>([]);
  const [isLgaDisabled, setLgaDisabled] = useState<boolean>(false);
  useEffect(() => {
    const { state } = profileForm.values;
    setLgaOptions([]);
    if (!state || state?.length === 0) {
      profileForm.setValues({
        lga: "",
      });
    }
    setLgaDisabled(state?.length === 0 ? true : false);
    const filter = States.find((s) => s.alias === state);

    if (filter) {
      const map = filter.lgas.map((lga) => {
        return { value: lga, label: lga };
      });

      setLgaOptions(map);
    }
  }, [profileForm.values.state]);

  const UpdateProfile = async () => {
    const { hasErrors: isError } = profileForm.validate();
    if (!isError) {
      setLoading(true);
      const { phone, gender, lga, state, firstname, lastname, email, area } =
        profileForm.values;
      const values = {
        phone: phone.toString(),
        gender,
        firstname,
        lastname,
        email,
        address: {
          lga,
          state,
          area,
        },
      };
      const r: DefaultResponse = await PerformRequest({
        method: "PATCH",
        data: values,

        route: Endpoints.UpdateProfile,
      }).catch(() => {
        setLoading(false);
        DefaultErrorNotification("Could not update profile! Please try again");
      });
      setLoading(false);
      if (r && r.data && r.data.statusCode === 200) {
        DefaultSuccessNotification("Updated Profile!");
        if (context) {
          context.reloadProfile();
        }
      }
    } else {
    }
  };
  return (
    <Container maxWidth="lg" className="single-admin-container">
      {context && context.isLoadingProfile ? (
        <Loader />
      ) : (
        <>
          {context && context?.profile && context.profile.id && (
            <>
              <div className="flex-row justify-between width-100">
                <div className="flex-col justify-between width-100">
                  <Text fw={500} size="20px" mb={5}>
                    My Profile
                  </Text>
                  <div className="flex-row align-center">
                    <IconClock color="grey" size={20} />
                    <Text c="dimmed">&nbsp; Created On: &nbsp;</Text>
                    <Text fw={500}>
                      {getFullDate(context.profile.createdAt)}
                    </Text>
                  </div>
                </div>
              </div>
              <br />
              <Grid container spacing={2}>
                <Grid item {...gridStatsProps}>
                  <TextInput
                    placeholder="First Name"
                    label="First Name"
                    {...profileForm.getInputProps("firstname")}
                  />
                </Grid>
                <Grid item {...gridStatsProps}>
                  <TextInput
                    placeholder="Last Name"
                    label="Last Name"
                    {...profileForm.getInputProps("lastname")}
                  />
                </Grid>
                <Grid item {...gridStatsProps}>
                  <TextInput
                    placeholder="Email Address"
                    label="Email Address"
                    {...profileForm.getInputProps("email")}
                  />
                </Grid>
                <Grid item {...gridStatsProps}>
                  <NumberInput
                    placeholder="Phone"
                    label="Phone"
                    hideControls
                    prefix="+234 "
                    min={0}
                    {...profileForm.getInputProps("phone")}
                  />
                </Grid>
                <Grid item xs={12} xl={12}>
                  <Select
                    {...profileForm.getInputProps("gender")}
                    placeholder="Select Gender"
                    label="Gender"
                    data={[
                      { label: "Male", value: "male" },
                      { label: "Female", value: "female" },
                    ]}
                  />
                </Grid>
              </Grid>
              <br />
              <div className="flex-row width-100 justify-between">
                <Select
                  label="Select State"
                  placeholder="Select your location"
                  w="49%"
                  data={states}
                  {...profileForm.getInputProps("state")}
                />
                <Select
                  label="LGA"
                  placeholder="Select LGA"
                  data={lgaOptions}
                  disabled={isLgaDisabled}
                  w="49%"
                  {...profileForm.getInputProps("lga")}
                />
              </div>
              <br />

              <Textarea
                label="Address"
                placeholder="Enter your address"
                {...profileForm.getInputProps("area")}
              />
              <br />
              <Button fullWidth onClick={UpdateProfile} loading={isLoading}>
                Save Profile
              </Button>
              <br />
            </>
          )}
        </>
      )}
    </Container>
  );
}
