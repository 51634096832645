import { useEffect, useState } from "react";

import {
  useNavigate,
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import { Container } from "@mui/material";
import { nprogress } from "@mantine/nprogress";

import {
  Card,
  PinInput,
  Image,
  Menu,
  NavLink,
  Burger,
  SimpleGrid,
  AppShell,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";

import { IconMenu2, IconX } from "@tabler/icons-react";

import Logo from "../../Assets/IMG/Logo.svg";
import "./styles.scss";
import DashboardNav from "../DashboardNav";

import Account from "../Account";
import { Footer } from "../Footer";
import { PerformRequest, usePerformRequest } from "../../Lib/usePerformRequest";
import {
  Admin,
  CartProduct,
  Outlet,
  PaginationProps,
  Patient,
  Service,
  Vendor,
} from "../../Lib/Types";
import { Endpoints, baseURL } from "../../Lib/Endpoints";
import { AppContext, ContextProps } from "../../Context/AppContext";
import Cookies from "js-cookie";
import Department from "../Department";
import Overview from "../Overview";
import ViewProduct from "../ViewProduct";
import Cart from "../Cart";
import SingleTransaction from "../SingleTransaction";
import Transactions from "../Transactions";
import { DefaultResponse, GetCartResponse } from "../../Lib/Responses";
import {
  DefaultErrorNotification,
  DefaultSuccessNotification,
} from "../../Lib/Methods";
import SearchResults from "../SearchResults";

export default function Dashboard() {
  const navigate = useNavigate();

  useEffect(() => {
    nprogress.start();
    Cookies.remove("hasPendingRoute");
    document.title = "Dashboard - Quickhealth";
  }, []);

  const [cart, setCart] = useState<CartProduct[]>([]);
  const [isCartLoading, setCartLoading] = useState<boolean>(false);
  const reloadCart = async () => {
    if (profile && profile.id) {
      setCartLoading(true);
      const r: GetCartResponse = await PerformRequest({
        method: "GET",
        data: {},
        route: Endpoints.GetPatientCart,
      }).catch(() => {
        setCartLoading(false);
        DefaultErrorNotification("Could not get cart");
      });
      setCartLoading(false);
      if (r && r.data && r.data.statusCode === 200) {
        setCart(r.data.data.cart);
      } else {
        DefaultErrorNotification("Could not get cart");
      }
    }
  };
  const addToCart = async (product: CartProduct) => {
    setCartLoading(true);
    const r: DefaultResponse = await PerformRequest({
      method: "PUT",
      data: product,
      route: Endpoints.AddToCart,
    }).catch(() => {
      setCartLoading(false);
    });
    setCartLoading(false);
    if (r.data && [200, 201].includes(r.data.statusCode)) {
      DefaultSuccessNotification("Product added to cart!");
      reloadCart();
    } else {
      DefaultErrorNotification("Could not add product to cart");
    }
  };

  const removeFromCart = async (product: CartProduct) => {
    if (profile && profile.id) {
      setCartLoading(true);
      const r: DefaultResponse = await PerformRequest({
        method: "PATCH",
        route: `${Endpoints.RemoveFromCart}=${product.productId}`,
        data: { quantity: 1 },
      }).catch(() => {
        setCartLoading(false);
      });
      setCartLoading(false);
      if (r.data && r.data.statusCode === 200) {
        DefaultSuccessNotification("Product removed from cart!");
        reloadCart();
      } else {
        DefaultErrorNotification("Could not remove product from cart");
      }
    }
  };

  const {
    data: profile,
    reloadData: reloadProfile,
    isLoading: isLoadingProfile,
  } = usePerformRequest<Patient>({
    method: "GET",
    url: Endpoints.GetPatientProfile,
  });
  useEffect(() => {
    reloadCart();
  }, [profile]);

  useEffect(() => {
    nprogress.complete();
  });

  const {
    data: products,
    isLoading: isLoadingProducts,
    reloadData: reloadProducts,
  } = usePerformRequest<Service[]>({
    method: "POST",
    body: {
      department: "diagnostics",
    },
    url: Endpoints.GetProducts,
  });
  const {
    data: vendors,
    isLoading: isLoadingVendors,
    reloadData: reloadVendors,
  } = usePerformRequest<Vendor[]>({
    method: "GET",

    url: Endpoints.GetAllVendors,
  });
  const {
    data: outlets,
    isLoading: isLoadingOutlets,
    reloadData: reloadOutlets,
  } = usePerformRequest<Outlet[]>({
    method: "GET",

    url: Endpoints.GetAllOutlets,
  });

  const [searchResults, setSearchResults] = useState<Service[]>([]);

  const logoutPatient = () => {
    Cookies.remove("token");
    localStorage.removeItem("token");
    navigate("/login");
  };

  return (
    <>
      <AppContext.Provider
        value={{
          isAuthenticated: profile !== undefined && profile.id !== undefined,
          profile,
          reloadProfile,
          isLoadingProfile,

          searchResults,
          setSearchResults,

          vendors,
          outlets,
          reloadVendors,
          isLoadingVendors,
          products,
          reloadProducts,
          isLoadingProducts,
          cart: {
            products: cart,
            addToCart,
            removeFromCart,
            reloadCart,
            isCartLoading,
          },
          logoutPatient: logoutPatient,
        }}
      >
        <DashboardNav />

        <Routes>
          <Route index path="/" element={<Overview />} />
          <Route index path="/account" element={<Account />} />
          <Route index path="/transactions" element={<Transactions />} />
          <Route
            index
            path="/transaction/:transactionID"
            element={<SingleTransaction />}
          />
          <Route index path="/search" element={<SearchResults />} />
          <Route index path="/cart" element={<Cart />} />
          <Route index path="/product/:productID" element={<ViewProduct />} />
          <Route
            index
            path="/diagnostics/:productID"
            element={<ViewProduct department="diagnostics" />}
          />
          <Route
            index
            path="/pharmacy/:productID"
            element={<ViewProduct department="pharmacy" />}
          />
          <Route
            index
            path="/dentistry/:productID"
            element={<ViewProduct department="dentistry" />}
          />
          <Route
            index
            path="/ambulance/:productID"
            element={<ViewProduct department="ambulance" />}
          />
          <Route
            index
            path="/ambulance/:productID"
            element={<ViewProduct department="ambulance" />}
          />
          <Route
            index
            path="/medical-supplies/:productID"
            element={<ViewProduct department="medical_supplies" />}
          />
          <Route
            index
            path="/department/:departmentName"
            element={<Department />}
          />
        </Routes>
        {/* <Footer /> */}
      </AppContext.Provider>
    </>
  );
}
