import axios, { AxiosRequestConfig } from "axios";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { baseURL as defaultBaseURL } from "./Endpoints";
import { DefaultResponse } from "./Responses";
interface PerformRequestOptions {
  method: "POST" | "PATCH" | "GET" | "PUT";
  data?: any;
  route: string;
  headers?: any;
  doNotUseAuthorization?: boolean;
}

const PerformRequest = async ({
  method,
  data,
  route,
  headers,
  doNotUseAuthorization,
}: PerformRequestOptions) => {
  const token = Cookies.get("token") ?? localStorage.getItem("token");

  const requestHeaders = doNotUseAuthorization
    ? { ...headers }
    : { Authorization: `Bearer ${token}`, ...headers };
  const config = {
    method,
    data,
    url: route,
    headers: requestHeaders,
  };
  const r: any = axios.request(config);
  return r;
};

const UploadFile = async (file: File) => {
  const token = Cookies.get("token") ?? localStorage.getItem("token");

  const fileFormData = new FormData();
  fileFormData.append("token", token ?? "");
  fileFormData.append(
    "file",
    file,
    file.name.toLowerCase().split(" ").join().replaceAll(",", "")
  );
  const config: AxiosRequestConfig = {
    method: "POST",
    url: `${defaultBaseURL}`,

    data: fileFormData,
    maxBodyLength: Infinity,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  const response = await axios.request(config);

  return response as any;
};

interface RequestOptions {
  method: "POST" | "GET" | "PUT" | "PATCH";
  url: string;
  body?: any;
}
function usePerformRequest<Type, ResponseType = DefaultResponse>({
  method,
  url,
  body,
}: RequestOptions) {
  const token = Cookies.get("token") ?? localStorage.getItem("token");

  const [data, setData] = useState<Type>();
  const [response, setResponse] = useState<ResponseType>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const reloadData = async () => {
    const config: AxiosRequestConfig = {
      method,
      data: body,
      url,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setLoading(true);
    const r = await axios(config);
    setLoading(false);
    if (r.data) {
      setResponse(r.data);
      setData(
        r.data.statusCode === 200 || r.data.statuscode === 200
          ? r.data.payload ?? r.data.data
          : undefined
      );
    }
  };
  useEffect(() => {
    reloadData().catch(() => setLoading(false));
  }, [url]);

  return { response, data, isLoading, reloadData };
}

const GetHealthConnectToken = async () => {
  let data = new FormData();
  data.append("grant_type", "client_credentials");
  data.append("client_id", "66");
  data.append("client_secret", "BdvoEWTDFhLT1sPBnBQeIclDCiGjELOn2lEDHZtK");

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://aohs1.trudoc24x7.info/api/oauth/token",

    data: data,
  };

  return await axios(config);
};
export { usePerformRequest, PerformRequest, GetHealthConnectToken };
