import React, { useEffect } from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import { MantineProvider } from "@mantine/core";
import { NavigationProgress, nprogress } from "@mantine/nprogress";
import { Notifications } from "@mantine/notifications";

import "./index.scss";
import "@mantine/core/styles.css";
import "@mantine/nprogress/styles.css";
import "@mantine/notifications/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/dropzone/styles.css";
import "@mantine/carousel/styles.css";

import Login from "./Pages/Login";
import Register from "./Pages/Register";
import Dashboard from "./Pages/Dashboard";
import Account from "./Pages/Account";
import Department from "./Pages/Department";
import ViewProduct from "./Pages/ViewProduct";
import Cart from "./Pages/Cart";
import SingleTransaction from "./Pages/SingleTransaction";
import Transactions from "./Pages/Transactions";
import SearchResults from "./Pages/SearchResults";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ForgotPassword } from "./Pages/ForgotPassword";

function RedirectToDashboard() {
  const navigate = useNavigate();
  useEffect(() => {
    nprogress.start();
    document.title = "Dashboard - Quickhealth";
    navigate("/dashboard");
  }, []);

  useEffect(() => {
    nprogress.complete();
  });
  return <span></span>;
}
function App() {
  return (
    <MantineProvider>
      <GoogleOAuthProvider clientId="1067440555472-47d65brpbrvdr8pol88h81caaam3hnf8.apps.googleusercontent.com">
        <NavigationProgress />
        <Notifications position="top-right" />

        <Router>
          <Routes>
            {/* <Route path="/" index element={<Home />} /> */}
            <Route path="/" index element={<RedirectToDashboard />} />
            <Route path="/dashboard" element={<Dashboard />}>
              <Route index path="/dashboard/cart" element={<Cart />} />
              <Route index path="/dashboard/account" element={<Account />} />
              <Route
                index
                path="/dashboard/transactions"
                element={<Transactions />}
              />
              <Route
                index
                path="/dashboard/transaction/:transactionID"
                element={<SingleTransaction />}
              />
              <Route
                index
                path="/dashboard/diagnostics/:productID"
                element={<ViewProduct department="diagnostics" />}
              />
              <Route
                index
                path="/dashboard/dentistry/:productID"
                element={<ViewProduct department="dentistry" />}
              />
              <Route
                index
                path="/dashboard/pharmacy/:productID"
                element={<ViewProduct department="pharmacy" />}
              />
              <Route
                index
                path="/dashboard/medical-supplies/:productID"
                element={<ViewProduct department="medical_supplies" />}
              />
              <Route
                index
                path="/dashboard/ambulance/:productID"
                element={<ViewProduct department="ambulance" />}
              />
              <Route
                index
                path="/dashboard/product/:productID"
                element={<ViewProduct />}
              />
              <Route
                index
                path="/dashboard/department/:departmentName"
                element={<Department />}
              />
              <Route path="/dashboard/search" element={<SearchResults />} />
            </Route>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/reset-password" element={<ForgotPassword />} />
          </Routes>
        </Router>
      </GoogleOAuthProvider>
    </MantineProvider>
  );
}

export default App;
