import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import { Container, Grid } from "@mui/material";
import { Text, Pagination, Paper, Badge, Button } from "@mantine/core";
import { nprogress } from "@mantine/nprogress";

import { ProductCard } from "../ProductCard";

import Loader from "../Misc/Loader";
import { usePerformRequest } from "../../Lib/usePerformRequest";
import { PaginationProps, Service } from "../../Lib/Types";
import { Endpoints } from "../../Lib/Endpoints";
import { PaginatedResponse } from "../../Lib/Responses";

import SynlabLogo from "../../Assets/IMG/SynlabLogo.png";
import "./styles.scss";

export default function Diagnostics() {
  useEffect(() => {
    nprogress.start();
    document.title = "Quickhealth - Diagnostics";
  }, []);

  useEffect(() => {
    nprogress.complete();
  });

  const params = useParams();

  const [productPagination, setProductPagination] = useState<PaginationProps>({
    page: 1,
    limit: 20,
  });

  const [selectedVendor, setSelectedVendor] = useState<string>("");
  const {
    data: products,
    response: productsResponse,
    isLoading: isLoadingProducts,
    reloadData: reloadProducts,
  } = usePerformRequest<Service[], PaginatedResponse<Service>>({
    method: "GET",

    // url: `${Endpoints.GetAllProducts}?page=${productPagination.page}&limit=${productPagination.limit}&department=diagnostics`,
    url: `${Endpoints.GetAllProducts}/${selectedVendor}?page=${productPagination.page}&limit=${productPagination.limit}`,
  });

  useEffect(() => {
    reloadProducts();
  }, [productPagination, selectedVendor]);
  return (
    <Container maxWidth="xl" className="department-container">
      <div className="width-100">
        <div className="flex-row align-center">
          <Text fw={500} size="25px" className="capitalize">
            Diagnostics
          </Text>
          <Button
            ml={20}
            variant="outline"
            onClick={() => {
              setSelectedVendor(
                selectedVendor === "200c3180-d9ef-46c9-96b6-85601fb52e6f"
                  ? "6adf8ea9-6f7a-46f5-aea7-b4e55ae37e37"
                  : "200c3180-d9ef-46c9-96b6-85601fb52e6f"
              );
            }}
          >
            <Text c="blue">
              Go to{" "}
              {selectedVendor === "200c3180-d9ef-46c9-96b6-85601fb52e6f"
                ? "Synlab"
                : "Clinix"}
            </Text>
          </Button>
        </div>
        <br />
        {selectedVendor === "" ? (
          <div className="flex-row align-center justify-center vendors">
            <Paper
              withBorder={false}
              className="vendor"
              onClick={() => {
                setSelectedVendor("200c3180-d9ef-46c9-96b6-85601fb52e6f");
                // setProductPagination({ ...productPagination, page: 1 });
              }}
            >
              <img
                className="image"
                src="https://res.cloudinary.com/quickhealth/image/upload/v1712398389/uvnppfdvninuhrj4puwo.png"
                alt=""
              />
              <Badge fw={500} size="lg" radius="xs">
                <Text fz="sm">Clinix Nigeria</Text>
              </Badge>
            </Paper>
            <Paper
              withBorder={false}
              className="vendor"
              onClick={() => {
                setSelectedVendor("6adf8ea9-6f7a-46f5-aea7-b4e55ae37e37");
                // setProductPagination({ ...productPagination, page: 1 });
              }}
            >
              <img className="image" src={SynlabLogo} alt="" />
              <Badge fw={500} size="lg" radius="xs">
                <Text fz="sm">Synlab Nigeria</Text>
              </Badge>
            </Paper>
          </div>
        ) : (
          <>
            {products && !isLoadingProducts ? (
              <Grid
                container
                rowSpacing={10}
                columnSpacing={10}
                alignItems="center"
                justifyContent="center"
              >
                {products.map((product) => {
                  return (
                    <Grid item>
                      <ProductCard department="diagnostics" product={product} />
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              <Loader />
            )}
          </>
        )}
      </div>
      <Pagination
        className="pagination"
        my={30}
        onChange={(e) => {
          setProductPagination({ ...productPagination, page: e });
        }}
        total={Math.ceil((productsResponse?.total ?? 100) / 20)}
      />
    </Container>
  );
}
