import { Dispatch, createContext } from "react";
import { CartProduct, Outlet, Patient, Service, Vendor } from "../Lib/Types";

export interface ContextProps {
  isAuthenticated: boolean;
  profile: Patient | undefined;
  isLoadingProfile: boolean;
  reloadProfile: () => void;
  products: Service[] | undefined;
  searchResults: Service[];
  setSearchResults: React.Dispatch<React.SetStateAction<Service[]>>;
  isLoadingProducts: boolean;
  reloadProducts: () => void;

  vendors: Vendor[] | undefined;
  outlets: Outlet[] | undefined;
  isLoadingVendors: boolean;
  reloadVendors: () => void;
  cart: {
    products: CartProduct[];
    addToCart: (product: CartProduct) => {};
    removeFromCart: (product: CartProduct) => {};
    reloadCart: () => void;
    isCartLoading: boolean;
  };

  logoutPatient: () => void;
}
const AppContext = createContext<ContextProps | null>(null);

export { AppContext };
