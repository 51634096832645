import { useContext, useState, useEffect, useRef } from "react";

import { Link, useNavigate } from "react-router-dom";

import { Container } from "@mui/material";
import States from "../../Lib/States.json";
import {
  Avatar,
  Text,
  Menu,
  NavLink,
  ActionIcon,
  AppShell,
  TextInput,
  Modal,
  Indicator,
  TextInputProps,
  Select,
  Button,
  Group,
  Divider,
  Loader,
} from "@mantine/core";

import { motion } from "framer-motion";
import { useDisclosure } from "@mantine/hooks";
import { useForm } from "@mantine/form";
import {
  IconLogout2,
  IconMenu2,
  IconX,
  IconUserCircle,
  IconSearch,
  IconMedicineSyrup,
  IconSwitchHorizontal,
  IconLogout,
  IconCategory,
  IconShoppingCart,
  IconReceipt,
} from "@tabler/icons-react";

import Logo from "../../Assets/IMG/Logo.svg";
import "./styles.scss";
import classes from "./module.module.css";

import { AppContext } from "../../Context/AppContext";
import { ProtectedRouteList } from "../../Lib/ProtectedRouteList";
import { Department, Service } from "../../Lib/Types";
import { notifications } from "@mantine/notifications";
import { DepartmentRouteList } from "../../Lib/Routelist";
import {
  PaginatedResponse,
  PerformRequestPaginatedResponse,
} from "../../Lib/Responses";
import { PerformRequest } from "../../Lib/usePerformRequest";
import { Endpoints } from "../../Lib/Endpoints";
import { DefaultErrorNotification } from "../../Lib/Methods";

export default function DashboardNav() {
  const context = useContext(AppContext);
  const navigate = useNavigate();

  const LogoutPatient = async () => {
    context && context.logoutPatient();
  };

  const searchInputRef = useRef<HTMLInputElement>(null);
  const [isSearching, setSearching] = useState<boolean>(false);

  const [
    isSearchModalOpen,
    { open: OpenSearchModal, close: CloseSearchModal },
  ] = useDisclosure(false);

  interface ProductSearchProps {
    text: string; // Product search string
    vendor: string;
    state: string;
    lga: string;
  }
  const productSearchForm = useForm<ProductSearchProps>({
    initialValues: {
      text: "",
      vendor: "",
      state: "",
      lga: "",
    },
    validate: {
      text: (value) => (value.length === 0 ? true : false),
    },
  });

  const SearchForProduct = async () => {
    const { text, vendor, state, lga } = productSearchForm.values;
    if (text.length === 0) {
      notifications.show({
        color: "orange",
        title: "Empty search",
        message: "Please enter a search term",
      });
    } else {
      CloseSearchModal();

      navigate(
        `/dashboard/search?text=${text}&vendor=${vendor}&lga=${lga}&state=${state}`
      );
    }
  };

  const inputProps: TextInputProps = {
    style: { width: "100%" },
    spellCheck: false,
  };

  const states = States.map((state, index) => {
    return { value: state.alias, label: state.state };
  });
  const [lgaOptions, setLgaOptions] = useState<any[]>([]);
  const [isLgaDisabled, setLgaDisabled] = useState<boolean>(false);
  useEffect(() => {
    const { state } = productSearchForm.values;
    setLgaOptions([]);
    setLgaDisabled(state.length === 0 ? true : false);
    const filter = States.find((s) => s.alias === state);
    if (filter) {
      const map = filter.lgas.map((lga) => {
        return { value: lga, label: lga };
      });
      setLgaOptions(map);
    }
  }, [productSearchForm.values.state]);

  const [isDepartmentsOpen, setDepartmentsOpen] = useState<boolean>(false);
  const vendors =
    context && context.vendors
      ? context?.vendors?.map((v) => {
          return { value: v.id, label: v.vendorname };
        })
      : [];

  const [activeRoute, setActiveRoute] = useState<string>("Billing");

  return (
    <AppShell>
      <AppShell.Header
        style={{ paddingTop: "10px", paddingBottom: "10px" }}
        id="do-not-print"
      >
        <Container maxWidth="xl" className="dashboard-nav-container">
          <div className="flex-row dashboard-nav align-center justify-between">
            <Link to="/" className="home-link">
              <img src={Logo} alt="" />
            </Link>
            <div className="search">
              <TextInput
                leftSection={<IconSearch size={18} />}
                spellCheck={false}
                onClick={OpenSearchModal}
                placeholder="Search Products..."
                className="input"
                onInput={() => {
                  OpenSearchModal();
                  searchInputRef.current?.focus();
                }}
                {...productSearchForm.getInputProps("text")}
              />
            </div>
            <div className="right flex-row align-center">
              <Link to="/dashboard/cart">
                <Indicator
                  disabled={
                    context ? context?.cart.products.length === 0 : true
                  }
                  position="top-start"
                >
                  <Button
                    variant="subtle"
                    // radius="50%"
                    style={{
                      width: "30px",
                      height: "30px",
                      padding: "1px",
                    }}
                  >
                    <IconShoppingCart size={22} />
                  </Button>
                </Indicator>
              </Link>
              &nbsp; &nbsp;
              {context && (
                <>
                  {context.profile ? (
                    <Menu>
                      <Menu.Target>
                        <Avatar
                          src={null}
                          alt="no image here"
                          color="indigo"
                          style={{ cursor: "pointer" }}
                        />
                      </Menu.Target>
                      <Menu.Dropdown>
                        <Menu.Label>
                          <Text size="xs" className="capitalize">
                            {context?.profile?.firstname}{" "}
                            {context?.profile?.lastname}
                          </Text>
                        </Menu.Label>
                        <Link to="/dashboard/account">
                          <Menu.Item
                            color="blue"
                            leftSection={<IconUserCircle />}
                          >
                            My Account
                          </Menu.Item>
                        </Link>
                        <Link to="/dashboard/transactions">
                          <Menu.Item color="blue" leftSection={<IconReceipt />}>
                            Transaction History
                          </Menu.Item>
                        </Link>
                        <Menu.Divider />
                        <Menu.Item
                          color="red"
                          leftSection={<IconLogout2 />}
                          onClick={LogoutPatient}
                        >
                          Logout
                        </Menu.Item>
                      </Menu.Dropdown>
                    </Menu>
                  ) : (
                    <>
                      {context.isLoadingProfile ? (
                        <Loader size="sm" />
                      ) : (
                        <Text c="blue">
                          <Link
                            style={{
                              color: "#1971c2",
                            }}
                            to="/login"
                          >
                            Login
                          </Link>
                        </Text>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>

          <Modal
            opened={isSearchModalOpen}
            onClose={CloseSearchModal}
            title="Search Products"
            centered
            size="xl"
          >
            <form action="#" className="search-products">
              <TextInput
                label="Search"
                placeholder="Enter search term e.g Ibuprofen"
                leftSection={<IconSearch size={16} />}
                disabled={isSearching}
                ref={searchInputRef}
                {...productSearchForm.getInputProps("text")}
                {...inputProps}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    SearchForProduct();
                  }
                }}
              />
              <br />
              <Select
                label="Filter by Vendor"
                placeholder="Select Vendor"
                disabled={isSearching}
                className="form-cell"
                data={vendors}
                {...productSearchForm.getInputProps("vendor")}
              />
              <br />
              <Group justify="space-between">
                <Select
                  label="Select State"
                  placeholder="Select your location"
                  disabled={isSearching}
                  className="form-select"
                  data={[{ value: "", label: "All States" }, ...states]}
                  {...productSearchForm.getInputProps("state")}
                />
                <Select
                  label="LGA"
                  placeholder="Select LGA closest"
                  disabled={isSearching || isLgaDisabled}
                  data={lgaOptions}
                  className="form-select"
                  {...productSearchForm.getInputProps("lga")}
                />
              </Group>
              <br />

              <br />
              <Button
                fullWidth
                loading={isSearching}
                style={{
                  fontSize: "14px",
                }}
                onClick={SearchForProduct}
              >
                Submit
              </Button>
            </form>
          </Modal>
        </Container>
      </AppShell.Header>
      <Container maxWidth="xl" className="dashboard-departments">
        <Menu
          shadow="md"
          opened={isDepartmentsOpen}
          position="bottom-start"
          width={340}
          onChange={(e) => {
            setDepartmentsOpen(e);
          }}
        >
          <Menu.Target>
            <Button
              mt={20}
              variant="filled"
              leftSection={
                isDepartmentsOpen ? (
                  <IconX size={18} />
                ) : (
                  <>
                    <IconCategory size={18} />
                  </>
                )
              }
            >
              &nbsp;All Departments
            </Button>
          </Menu.Target>
          <Menu.Dropdown
            style={{
              height: "500px",
              justifyContent: "space-between",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {DepartmentRouteList.map((route) => {
              return (
                <>
                  <NavLink
                    href={`/dashboard/department/${route.route}`}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(`/dashboard/department/${route.route}`);
                      setDepartmentsOpen(false);
                    }}
                    h={50}
                    c="#1864AB"
                    fw={500}
                    active={route.route === activeRoute}
                    className="link"
                    label={route.label}
                    leftSection={<route.icon size={25} stroke={1.5} />}
                  />
                  <div className="break"></div>
                </>
              );
            })}
          </Menu.Dropdown>
        </Menu>
      </Container>
      <br />
    </AppShell>
  );
}
