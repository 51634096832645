import { useEffect, useContext, useState } from "react";

import { Link, useNavigate } from "react-router-dom";

import { Container, Grid } from "@mui/material";

import {
  PaystackConsumer,
  PaystackButton,
  usePaystackPayment,
} from "react-paystack";
import { Paper, Text, Alert, Badge, Button, Table, rem } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { Carousel } from "@mantine/carousel";
import {
  DefaultErrorNotification,
  DefaultSuccessNotification,
  generatePaymentTransaction,
  getFinancialValueFromNumeric,
} from "../../Lib/Methods";
import { AppContext } from "../../Context/AppContext";
import {
  IconCheck,
  IconMinus,
  IconPlus,
  IconShoppingCart,
  IconX,
} from "@tabler/icons-react";

import "./styles.scss";
import Loader from "../Misc/Loader";
import {
  CreateCheckoutResponse,
  DefaultResponse,
  MakePaymentResponse,
} from "../../Lib/Responses";
import { PerformRequest } from "../../Lib/usePerformRequest";
import { Endpoints } from "../../Lib/Endpoints";
import { notifications } from "@mantine/notifications";
import { PaystackSuccess } from "../../Lib/Types";
import Cookies from "js-cookie";

export default function Cart() {
  const context = useContext(AppContext);
  const navigate = useNavigate();
  const cart = context?.cart.products ? context.cart.products : [];

  const totalCart = () => {
    if (cart.length !== 0) {
      const itemPrices = cart.map((c) => {
        const count = c.quantity ? c.quantity : 1;
        return count * c.price;
      });
      const reduced = itemPrices.reduce((prevAmount, currentAmount) => {
        return prevAmount + currentAmount;
      });

      return reduced;
    } else {
      return 0;
    }
  };
  let config = {
    reference: "",
    email: context?.profile?.email ?? "",
    amount: totalCart() * 100,
    publicKey: process.env.REACT_APP_PK_LIVE as string,
    // publicKey: process.env.REACT_APP_PK_TEST as string,
    checkout: undefined,
  };

  const [checkout, setCheckout] = useState<any>();

  const [isPaymentLoading, setPaymentLoading] = useState<boolean>(false);
  const StartPayment = async (
    initializePayment: any,
    handleSuccess: any,
    handleClose: any
  ) => {
    setPaymentLoading(true);
    const r: CreateCheckoutResponse = await PerformRequest({
      method: "POST",
      route: Endpoints.CreateCheckout,
    }).catch(() => {
      setPaymentLoading(false);
      DefaultErrorNotification("Could not checkout cart!");
    });
    if (r && r.data && r.data.statusCode === 201) {
      DefaultSuccessNotification("Checkout complete!");
      setCheckout("I fuckin hate JS");

      Cookies.set("checkout", r.data.data.token);
      const r2 = await generatePaymentTransaction({
        amount: (totalCart() * 100).toString(),
      });
      if (r2 && r2.reference) {
        config = { ...config, reference: r2.reference };
        initializePayment(handleSuccess, handleClose);
      }

      setPaymentLoading(false);
    }
  };

  const VerifyProductPayment = async (
    reference: string,
    notificationID: string
  ) => {
    setPaymentLoading(true);
    const r: DefaultResponse = await PerformRequest({
      method: "POST",
      route: `${Endpoints.VerifyProduct}?ref=${reference}`,
      data: {
        token: Cookies.get("checkout")?.toString(),
      },
    }).catch(() => {
      setPaymentLoading(false);

      DefaultErrorNotification("Could not complete payment!");
      notifications.update({
        id: notificationID,
        color: "red",
        title: "Payment Failed!",
        message: "Your payment could not be completed!",
        loading: false,
        icon: <IconX style={{ width: rem(18), height: rem(18) }} />,

        autoClose: 2500,
      });
    });
    setPaymentLoading(false);
    if (r && r.data && r.data.statusCode === 200) {
      DefaultSuccessNotification("Payment concluded!");
      notifications.update({
        id: notificationID,
        color: "green",
        title: "Payment Verified!",
        message: "Your payment has successfully been verified!",
        loading: false,
        icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,

        autoClose: 2500,
      });
    }
  };

  const handleSuccess = (reference: string) => {
    const notificationID = notifications.show({
      loading: true,
      color: "orange",
      withBorder: true,
      title: "Completing service purchase",
      message: "Please DO NOT leave this page! We are verifying your purchase.",
      autoClose: false,
      withCloseButton: false,
    });
    VerifyProductPayment(reference, notificationID);
  };

  // you can call this function anything
  const handleClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
  };

  const componentProps = {
    ...config,
    text: "Pay now",
    onSuccess: (e: PaystackSuccess) => {
      handleSuccess(e.reference);
    },
    onClose: handleClose,
  };
  // useEffect(() => {

  // }, [checkout]);
  return (
    <>
      {context ? (
        <Container maxWidth="lg">
          {context.cart.isCartLoading ? (
            <Loader />
          ) : (
            <>
              {context.cart.products.length === 0 ? (
                <Container maxWidth="sm">
                  <Alert title="Empty Cart">
                    <Text fz="md" fw={500}>
                      Your cart is empty!
                    </Text>
                    <div className="flex-row align-center">
                      <Text c="dimmed">Not sure what you need?</Text>
                      &nbsp; &nbsp;
                      <Link to="/dashboard/department/telemedicine">
                        <Button>Speak to a Doctor</Button>
                      </Link>
                    </div>
                  </Alert>
                </Container>
              ) : (
                <>
                  <div className="cart-container flex-row width-100 justify-between">
                    <div className="left">
                      <Table
                        className="products"
                        verticalSpacing="md"
                        withRowBorders={false}
                      >
                        <Table.Thead>
                          <Table.Tr>
                            <Table.Th>
                              <Text fw={500}>Image</Text>
                            </Table.Th>
                            <Table.Th>
                              <Text fw={500}>Name</Text>
                            </Table.Th>
                            <Table.Th>
                              <Text fw={500}>Vendor</Text>
                            </Table.Th>
                            <Table.Th>
                              <Text fw={500}>Quantity</Text>
                            </Table.Th>
                            <Table.Th>
                              <Text fw={500}>Price</Text>
                            </Table.Th>
                          </Table.Tr>
                        </Table.Thead>
                        <Table.Tbody>
                          {cart.map((product, index) => {
                            return (
                              <Table.Tr key={product.image}>
                                <Table.Td>
                                  <img
                                    src={product.image}
                                    className="image"
                                    alt=""
                                  />
                                </Table.Td>
                                <Table.Td>
                                  <Text className="label">{product.name}</Text>
                                </Table.Td>
                                <Table.Td>
                                  <Text className="label">Synlab Nigeria</Text>
                                </Table.Td>
                                <Table.Td>
                                  <div className="flex-row align-center justify-between btns">
                                    <Button
                                      className="btn"
                                      variant="subtle"
                                      onClick={() => {
                                        context.cart.addToCart({
                                          ...product,
                                          quantity: 1,
                                        });
                                      }}
                                    >
                                      <IconPlus size={16} />
                                    </Button>
                                    <Badge
                                      variant="filled"
                                      color="rgba(74, 74, 74, 1)"
                                      radius="xs"
                                      size="md"
                                    >
                                      {product.quantity}
                                    </Badge>
                                    <Button
                                      className="btn"
                                      variant="subtle"
                                      onClick={() => {
                                        context.cart.removeFromCart(product);
                                      }}
                                    >
                                      <IconMinus size={16} />
                                    </Button>
                                  </div>
                                </Table.Td>
                                <Table.Td>
                                  <Text className="price">
                                    ₦
                                    {getFinancialValueFromNumeric(
                                      product.price
                                    )}
                                  </Text>
                                </Table.Td>
                              </Table.Tr>
                            );
                          })}
                        </Table.Tbody>
                      </Table>
                    </div>
                    <Paper
                      withBorder
                      className="right flex-col justify-between"
                    >
                      <Text fz={19} fw={500}>
                        Order Summary
                      </Text>
                      <div className="flex-col">
                        <Text c="dimmed" fz="sm">
                          Total
                        </Text>
                        <Text mt={5} fz="xl" fw={500}>
                          ₦{getFinancialValueFromNumeric(totalCart())}
                        </Text>
                      </div>

                      {/* @ts-ignore */}
                      <PaystackConsumer {...componentProps}>
                        {({ initializePayment }) => (
                          <Button
                            loading={isPaymentLoading}
                            onClick={() => {
                              if (context && context.profile?.id) {
                                StartPayment(
                                  initializePayment,
                                  handleSuccess,
                                  handleClose
                                );
                              } else {
                                navigate("/login");
                              }
                            }}
                          >
                            Pay Now
                          </Button>
                        )}
                      </PaystackConsumer>
                    </Paper>
                  </div>
                  <div className="small-cart-container flex-col width-100">
                    {cart.map((product, index) => {
                      return (
                        <div className="product flex-row" key={index}>
                          <div className="body flex-row">
                            <img src={product.image} alt="" className="image" />
                            <div className="details flex-col justify-between">
                              <Text fw={500} size="sm">
                                {product.name}
                              </Text>
                              <Text fw={500} c="dimmed" size="sm">
                                Synlab Nigeria
                              </Text>
                              <div className="flex-row align-center justify-between btns">
                                <Button
                                  className="btn"
                                  variant="subtle"
                                  onClick={() => {
                                    context.cart.addToCart({
                                      ...product,
                                      quantity: 1,
                                    });
                                  }}
                                >
                                  <IconPlus size={16} />
                                </Button>
                                <Badge
                                  variant="filled"
                                  color="rgba(74, 74, 74, 1)"
                                  radius="xs"
                                  size="md"
                                >
                                  {product.quantity}
                                </Badge>
                                <Button
                                  className="btn"
                                  variant="subtle"
                                  onClick={() => {
                                    context.cart.removeFromCart(product);
                                  }}
                                >
                                  <IconMinus size={16} />
                                </Button>
                              </div>
                              <Text className="price" fz="lg" fw={500}>
                                ₦{getFinancialValueFromNumeric(product.price)}
                              </Text>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </>
          )}
        </Container>
      ) : (
        <Loader />
      )}
    </>
  );
}
