import { useContext, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import {
  Text,
  Button,
  Title,
  Badge,
  CopyButton,
  Loader,
  rem,
  Table,
  Divider,
} from "@mantine/core";

import { IconCheck, IconPhoneCall } from "@tabler/icons-react";
import { PaystackConsumer } from "react-paystack";
import Typewriter from "typewriter-effect";

import HealthConnectLogo from "../../Assets/IMG/HealthConnectBanner.png";
import HealthConnectBanner from "../../Assets/IMG/Departments/HealthConnectBanner.png";
import HealthConnectBanner2 from "../../Assets/IMG/Departments/HealthConnectBanner2.png";
import { notifications } from "@mantine/notifications";
import {
  AddExistingPatientToHealthConnectResponse,
  DefaultResponse,
  GenerateHealthConnectTokenResponse,
  MakePaymentResponse,
  PaginatedResponse,
} from "../../Lib/Responses";
import { PerformRequest, usePerformRequest } from "../../Lib/usePerformRequest";
import { Endpoints } from "../../Lib/Endpoints";
import {
  DefaultErrorNotification,
  DefaultSuccessNotification,
  generatePaymentTransaction,
  generateRandomString,
} from "../../Lib/Methods";

import { AppContext } from "../../Context/AppContext";
import dayjs from "dayjs";
import axios from "axios";
import { PaystackSuccess, Transaction } from "../../Lib/Types";
import { Grid } from "@mui/material";

// Whenever a patient is registered on the platform, we will create a Health Connect profile for him/her automatically

export default function Telemedicine() {
  const context = useContext(AppContext);
  const navigate = useNavigate();
  const [isMegaloaderActive, setMegaloaderActive] = useState<boolean>(false);

  const {
    data: transactions,
    response: transactionsResponse,
    isLoading: isLoadingTransactions,
    reloadData: reloadTransactions,
  } = usePerformRequest<Transaction[], PaginatedResponse<Transaction>>({
    method: "GET",

    url: `${Endpoints.GetTransactions}?page=${1}&limit=${50}`,
  });

  const [isPaymentLoading, setPaymentLoading] = useState<boolean>(false);

  const GenerateHealthConnectToken = async (notificationID: string) => {
    const r: GenerateHealthConnectTokenResponse = await PerformRequest({
      method: "POST",
      route: Endpoints.GetHealthConnectToken,
      data: {
        client_id: process.env.REACT_APP_HC_ID,
        client_secret: process.env.REACT_APP_HC_SECRET,
        grant_type: "client_credentials",
      },
    }).catch(() => {
      setMegaloaderActive(false);
      DefaultErrorNotification(
        "An error occurred while connecting to Health connect!"
      );
    });
    if (r && r.data) {
      AddPatientToHealthConnect(r.data.access_token, notificationID);
    }
  };
  const AddPatientToHealthConnect = async (
    access_token: string,
    notificationID: string
  ) => {
    let end_date = dayjs().add(30, "day").format("YYYY-MM-DD");

    const r: AddExistingPatientToHealthConnectResponse = await PerformRequest({
      method: "POST",
      route: Endpoints.AddExistingPatientToHealthConnect,
      headers: {
        "Access-Token": `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        service_id: 4,
        patient_id: parseInt(context?.profile?.hcId as string),
        end_date,
      }),
    }).catch(() => {
      setPaymentLoading(false);
      setMegaloaderActive(false);
    });
    setPaymentLoading(false);
    setMegaloaderActive(false);
    if (r && r.data && r.data.status === "success") {
      DefaultSuccessNotification("Health Connect service added!");
      notifications.update({
        id: notificationID,
        color: "green",
        title: "Payment Verified!",
        message: "Your payment has successfully been verified!",
        loading: false,
        icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,

        autoClose: 2500,
      });
      window.location.reload();
      setTelemedicineActive(true);
    }
  };

  const [isTelemedicineActive, setTelemedicineActive] =
    useState<boolean>(false);

  let config = {
    reference: "",
    email: context?.profile?.email ?? "",
    amount: 100000,
    publicKey: process.env.REACT_APP_PK_LIVE as string,
    // publicKey: process.env.REACT_APP_PK_TEST as string,
  };

  const VerifyServicePayment = async (reference: string) => {
    const r: DefaultResponse = await PerformRequest({
      method: "POST",
      route: `${Endpoints.VerifyService}?ref=${reference}`,
      data: {
        id: "6675c097a412b4fa62027648",
        name: "Speak To A Doctor",
        department: "telemedicine",
        provider: {
          id: "f2fd3266-250c-4d9a-bf63-6fce879f7bbc",
          name: "Health Connect",
        },
      },
    }).catch(() => {
      DefaultErrorNotification("Could not complete payment!");
    });
    if (r && r.data && r.data.statusCode === 201) {
      reloadTransactions();
      DefaultSuccessNotification("Payment completed!");
    }
  };

  const handleSuccess = (reference: string) => {
    setMegaloaderActive(true);
    const notificationID = notifications.show({
      loading: true,
      color: "orange",
      withBorder: true,
      title: "Completing service purchase",
      message: "Please DO NOT leave this page! We are verifying your purchase.",
      autoClose: false,
      withCloseButton: false,
    });
    // Add Subscription
    VerifyServicePayment(reference);
    GenerateHealthConnectToken(notificationID);
  };

  const handleClose = () => {
    setPaymentLoading(false);
    DefaultErrorNotification("Payment was cancelled! Please try again");
  };
  const componentProps = {
    ...config,
    text: "Pay now",
    onSuccess: (e: PaystackSuccess) => {
      handleSuccess(e.reference);
    },
    onClose: handleClose,
  };

  const [telemedicineTransactions, setTelemedicineTransactions] = useState<
    { days: number; token: string; createdAt: string }[]
  >([]);
  useEffect(() => {
    if (transactions) {
      // Find transactions that match TELEMEDICINE

      const f = transactions
        .filter((t) => t.offerings[0].department === "telemedicine")
        .map((e) => {
          return {
            days: dayjs().diff(dayjs(e.createdAt), "day"),
            token: e.token,
            createdAt: e.createdAt,
          };
        })
        .filter((days) => days.days <= 30);
      setTelemedicineTransactions(f);
      if (f.length !== 0) {
        setTelemedicineActive(true);
      }
    }
  }, [transactions]);

  const StartPayment = async (
    initializePayment: any,
    handleSuccess: any,
    handleClose: any
  ) => {
    setPaymentLoading(true);
    const r = await generatePaymentTransaction({ amount: "1000" });
    if (r && r.reference) {
      config = { ...config, reference: r.reference };
      initializePayment(handleSuccess, handleClose);
    }
  };
  return (
    <>
      {isMegaloaderActive || isLoadingTransactions ? (
        <MegaLoader />
      ) : (
        <div className="telemedicine-department width-100 flex-row align-start justify-between">
          <div className="left flex-col">
            <div className="flex-col">
              <div className="flex-row align-end title">
                <Title className="subtitle">Speak to a Doctor in&nbsp;</Title>
                <Title className="subtitle typewriter">
                  <Typewriter
                    options={{
                      strings: ["Hausa", "English", "Yoruba", "Igbo"],
                      loop: true,
                      autoStart: true,
                    }}
                  />
                </Title>
              </div>
              <Text className="desc">
                Premium access to the Best telemedicine service in Nigeria!
              </Text>
            </div>
            <div className="flex-row banners align-end">
              <img src={HealthConnectBanner} className="banner" alt="" />
              <img src={HealthConnectBanner2} className="banner2" alt="" />
            </div>
          </div>
          <div className="right flex-col">
            <img src={HealthConnectLogo} alt="" className="logo" />
            {isTelemedicineActive ? (
              <div className="flex-col telemedicine-active">
                <Text c="dimmed" fz="md">
                  Toll-Free Number
                </Text>
                <CopyButton value="08000432584">
                  {({ copied, copy }) => (
                    <Text
                      c="white"
                      fw={600}
                      fz="26px"
                      onClick={() => {
                        copy();
                        notifications.show({
                          color: "green",
                          message: "Copied Phone number!",
                        });
                      }}
                      className={`number flex-row align-center ${
                        copied ? "number-copied" : ""
                      }`}
                    >
                      <IconPhoneCall />
                      &nbsp; 08000432584
                    </Text>
                  )}
                </CopyButton>
                <Table withTableBorder verticalSpacing="md" mt={30}>
                  <Table.Tbody>
                    <Table.Tr>
                      <Table.Td>
                        <Text fz="lg"> Unique Token</Text>
                      </Table.Td>
                      <Table.Td>
                        <Text fw={600} c="blue" size="xl">
                          {telemedicineTransactions[0]?.token}
                        </Text>
                      </Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td>
                        <Text fz="lg">Expiry Date</Text>
                      </Table.Td>
                      <Table.Td>
                        <Text fw={600} c="blue" size="xl">
                          {dayjs(telemedicineTransactions[0].createdAt)
                            .add(30, "day")
                            .format("dddd DD MMMM YYYY")}
                        </Text>
                      </Table.Td>
                    </Table.Tr>
                  </Table.Tbody>
                </Table>
              </div>
            ) : (
              <>
                <Text fw={500} className="header">
                  How It Works
                </Text>
                <div className="flex-row">
                  <Text className="list-item">Make a payment of</Text>{" "}
                  &nbsp;&nbsp;
                  <Badge color="blue" size="lg" radius="xs">
                    ₦1,000
                  </Badge>
                </div>
                <Text className="list-item">
                  24/7 Access to a doctor and wellness expert at any time at no
                  cost
                </Text>
                <Text className="list-item">
                  Consult a doctor or medical expert as many times as you wish
                </Text>
                <Text className="list-item">
                  Access to healthcare in any of the indigenous languages
                </Text>
                <Divider />
                <div className="list-item">
                  <Text fw={600}>Speak to a:</Text>
                  <Grid container spacing={2}>
                    <Grid item>Pediatrician.</Grid>
                    <Grid item>Gynecologist.</Grid>
                    <Grid item>Dentist.</Grid>
                    <Grid item>Optician.</Grid>
                    <Grid item>Nutritionist.</Grid>
                    <Grid item>Physiotherapist.</Grid>
                  </Grid>
                </div>

                {/* @ts-ignore */}
                <PaystackConsumer {...componentProps}>
                  {({ initializePayment }) => (
                    <Button
                      loading={isPaymentLoading}
                      onClick={() => {
                        if (context && context.profile?.id) {
                          StartPayment(
                            initializePayment,
                            handleSuccess,
                            handleClose
                          );
                        } else {
                          navigate("/login");
                        }
                      }}
                    >
                      Pay Now
                    </Button>
                  )}
                </PaystackConsumer>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

const MegaLoader = () => {
  return (
    <div className="flex-col align-center justify-center">
      <div className="megaloader flex-row align-center justify-center width-100">
        <Loader color="blue" size={60} />
      </div>
    </div>
  );
};
