import { Skeleton } from "@mantine/core";

export default function Loader() {
  return (
    <>
      <Skeleton height={45} mt={10} radius="xs" />
      <Skeleton height={45} mt={10} radius="xs" />
      <Skeleton height={45} mt={10} radius="xs" />
      <Skeleton height={45} mt={10} radius="xs" />
      <Skeleton height={45} mt={10} radius="xs" />
      <Skeleton height={45} mt={10} radius="xs" />
      <Skeleton height={45} mt={10} radius="xs" />
      <Skeleton height={45} mt={10} radius="xs" />
    </>
  );
}
