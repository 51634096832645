const baseURL = process.env.REACT_APP_BASE_URL as string;
const healthConnectURL = process.env.REACT_APP_HEALTH_CONNECT_URL as string;
const Endpoints = {
  LoginWithOTP: `${baseURL}/auth/patient/login`,
  LoginWithPassword: `${baseURL}/auth/signin/password`,
  Register: `${baseURL}/patient/account/register`,
  ValidateOTP: `${baseURL}/auth/patient/otp`,
  RequestForgotOTP: `${baseURL}/auth/forgot`,
  ResetPasswordWithOTP: `${baseURL}/auth/verify/otp?token`,

  GetPatientProfile: `${baseURL}/patient/profile`,
  UpdateProfile: `${baseURL}/patient/profile/update`,

  GetPatientCart: `${baseURL}/patient/cart/all`,
  AddToCart: `${baseURL}/patient/cart/add`,
  RemoveFromCart: `${baseURL}/patient/cart/remove?item`,

  GetHealthConnectToken: "https://aohs1.trudoc24x7.info/api/oauth/token",
  RegisterHealthConnectPatient: `${baseURL}/patient/third-party/register`,
  AddPatientToHealthConnect:
    "https://aohs1.trudoc24x7.info/api/v1/census/addSinglePatient",
  AddExistingPatientToHealthConnect:
    "https://aohs1.trudoc24x7.info/api/v1/census/assignServiceToPatient",

  InitiatePayment: `${baseURL}/payment/remit`,
  VerifyService: `${baseURL}/patient/verify-service`,

  VerifyProduct: `${baseURL}/patient/verify-product`,
  CreateCheckout: `${baseURL}/patient/checkout/create`,

  GetTransactions: `${baseURL}/transaction/patient/all`,
  GetSingleTransaction: `${baseURL}/transaction`,

  // GetProducts: "http://localhost:8080/products/find",
  // GetSingleProduct: "http://localhost:8080/product/get",

  GetProducts: `${baseURL}/product/filter`,
  GetAllProducts: `${baseURL}/product/all`,
  GetSingleProduct: `${baseURL}/product/find`,

  GetAllVendors: `${baseURL}/vendor/list`,

  GetAllOutlets: `${baseURL}/outlet/all?page=1&limit=999`,

  LoginToHealthConnect: `${healthConnectURL}/oauth/token`,
  CreateHealthConnectRecord: `${healthConnectURL}/v1/census/addSinglePatient`,
  AddHealthConnectService: `${healthConnectURL}/v1/census/assignServiceToPatient`,
};

export { Endpoints, baseURL };
