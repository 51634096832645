import { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";

import { Container } from "@mui/material";
import { nprogress } from "@mantine/nprogress";

import {
  Card,
  Text,
  TextInput,
  Button,
  TextInputProps,
  NumberInput,
  PasswordInput,
  Select,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { DateInput, DatePickerInput } from "@mantine/dates";

import { IconAt, IconUser, IconPhone } from "@tabler/icons-react";
import dayjs from "dayjs";

import Logo from "../../Assets/IMG/Logo.svg";

import {
  GetHealthConnectToken,
  PerformRequest,
} from "../../Lib/usePerformRequest";

import { Endpoints } from "../../Lib/Endpoints";
import { DefaultResponse, RegistrationResponse } from "../../Lib/Responses";
import {
  DefaultErrorNotification,
  DefaultSuccessNotification,
  validateEmail,
  validatePassword,
} from "../../Lib/Methods";

import "./styles.scss";
import Cookies from "js-cookie";
export default function Register() {
  const navigate = useNavigate();
  useEffect(() => {
    nprogress.start();
    document.title = "Create an Account - Quickhealth";
  }, []);

  useEffect(() => {
    nprogress.complete();
  });
  const registerForm = useForm({
    initialValues: {
      email: "",
      firstname: "",
      lastname: "",
      phone: "",
      gender: "male",
      dateOfBirth: dayjs("2024-01-01").toDate(),

      password: "",
      confirmPassword: "",
    },
    validate: {
      email: (value) =>
        validateEmail(value) ? null : "Please enter a valid email",
      firstname: (value) => (value.length < 3 ? "Enter your name" : null),
      lastname: (value) => (value.length < 3 ? "Enter your name" : null),
      dateOfBirth: (value) => (!value ? "Choose your date of birth" : null),
      gender: (value) =>
        !value || value?.length === 0 ? "Select your gender" : null,
      phone: (value) =>
        value.toString().length < 10 ? "Enter a valid phone number" : null,
      password: (value) =>
        validatePassword(value) ? null : "At least 8 characters",
      confirmPassword: (value, values) =>
        values.password !== value ? "Passwords do not match" : null,
    },
  });

  const [isLoading, setLoading] = useState<boolean>(false);

  const RegisterPatientWithHealthConnect = async () => {
    const getToken = await GetHealthConnectToken();
    const token = getToken?.data?.access_token ?? "";

    const { gender, firstname, lastname, email, phone, dateOfBirth } =
      registerForm.values;
    const parsedDateOfBirth = dayjs(dateOfBirth).format("YYYY-MM-DD");
    setLoading(true);
    const r: DefaultResponse = await PerformRequest({
      route: Endpoints.RegisterHealthConnectPatient,
      method: "POST",
      data: {
        first_name: firstname,
        last_name: lastname,
        email,
        gender,
        mobile: `0${phone}`,
        date_of_birth: parsedDateOfBirth,
        token,
      },
    }).catch(() => {
      setLoading(false);
      DefaultErrorNotification("An error occurred");
    });
    if (r && r.data && r.data.statusCode === 201) {
      DefaultSuccessNotification("Registered!");
      navigate("/dashboard");
    }
    setLoading(false);
  };
  const RegisterPatient = async () => {
    const { hasErrors } = registerForm.validate();
    if (!hasErrors) {
      setLoading(true);
      const { firstname, lastname, email, phone, password, gender } =
        registerForm.values;
      const r: RegistrationResponse = await PerformRequest({
        method: "POST",
        route: Endpoints.Register,
        data: {
          email,
          password,
          firstname,
          lastname,
          phone: `0${phone.toString()}`,
          gender,
        },
      }).catch(() => {
        setLoading(false);
      });
      if (r && r.data) {
        if (r.data.statusCode === 201) {
          RegisterPatientWithHealthConnect();
          Cookies.set("token", r.data.data.token);
        } else if (r.data.statusCode === 406) {
          DefaultErrorNotification("Patient already exists!");
        } else {
          DefaultErrorNotification("An error occurred!");
        }
      } else {
        DefaultErrorNotification("An error occurred!");
      }
    }
  };

  const inputProps: TextInputProps = {
    style: { width: "100%" },
  };
  return (
    <Container maxWidth="lg">
      <div className="register-container flex-col align-center justify-center">
        <img src={Logo} alt="" />
        <br />
        <Card
          shadow="sm"
          padding="lg"
          radius="md"
          withBorder
          className="form-container"
        >
          <form
            className="register-form flex-col align-center justify-between"
            onSubmit={(e) => {
              e.preventDefault();
              RegisterPatient();
            }}
          >
            <Text fw={500}>Create an Account</Text>

            <TextInput
              label="Email"
              placeholder="Email Address"
              leftSection={<IconAt size={16} />}
              disabled={isLoading}
              {...registerForm.getInputProps("email")}
              {...inputProps}
            />

            <div className="flex-row form-row align-center justify-between">
              <TextInput
                label="Enter first name"
                placeholder="First Name"
                leftSection={<IconUser size={16} />}
                disabled={isLoading}
                className="form-cell"
                {...registerForm.getInputProps("firstname")}
              />
              <TextInput
                label="Enter last name"
                placeholder="Last Name"
                disabled={isLoading}
                className="form-cell"
                {...registerForm.getInputProps("lastname")}
              />
            </div>
            <div className="flex-row form-row align-center justify-between">
              <PasswordInput
                label="Enter password"
                placeholder="Password"
                leftSection={<IconUser size={16} />}
                disabled={isLoading}
                className="form-cell"
                {...registerForm.getInputProps("password")}
              />
              <PasswordInput
                label="Confirm password"
                placeholder="Confirm password"
                disabled={isLoading}
                className="form-cell"
                {...registerForm.getInputProps("confirmPassword")}
              />
            </div>
            <Select
              {...registerForm.getInputProps("gender")}
              label="Gender"
              w="100%"
              data={[
                { label: "Male", value: "male" },
                { label: "Female", value: "female" },
              ]}
              placeholder="Select gender"
              disabled={isLoading}
            />
            <DateInput
              label="Date of Birth"
              w="100%"
              maxDate={new Date()}
              disabled={isLoading}
              /*
              // @ts-ignore */
              placeholder="Date of Birth"
              {...registerForm.getInputProps("dateOfBirth")}
            />
            <NumberInput
              style={{
                width: "100%",
              }}
              label="Enter phone"
              placeholder="Phone number"
              clampBehavior="strict"
              min={0}
              leftSection={<IconPhone size={16} />}
              prefix="+(234) "
              hideControls
              disabled={isLoading}
              {...registerForm.getInputProps("phone")}
            />

            <Button
              fullWidth
              loading={isLoading}
              size="compact-md"
              className="register-btn"
              onClick={() => {
                RegisterPatient();
              }}
            >
              Submit
            </Button>
          </form>
          <br />
          <div className="flex-row width-100 justify-between">
            <Text size="sm">Already have an account?</Text>
            <Text c="blue" size="sm">
              <Link to="/login" className="auth-link">
                Sign In
              </Link>
            </Text>
          </div>
        </Card>
        <br />
      </div>
    </Container>
  );
}
