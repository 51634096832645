import { useContext, useEffect, useState } from "react";

import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import { Container, Grid } from "@mui/material";

import {
  Text,
  Modal,
  Button,
  Pill,
  rem,
  NumberInput,
  Divider,
  Select,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { Carousel } from "@mantine/carousel";
import {
  IconCheck,
  IconMinus,
  IconPlus,
  IconShoppingCart,
} from "@tabler/icons-react";

import { PaystackConsumer, usePaystackPayment } from "react-paystack";

import {
  DefaultErrorNotification,
  DefaultSuccessNotification,
  generatePaymentTransaction,
  getFinancialValueFromNumeric,
} from "../../Lib/Methods";
import { AppContext } from "../../Context/AppContext";

import "./styles.scss";
import { PerformRequest, usePerformRequest } from "../../Lib/usePerformRequest";
import { Endpoints } from "../../Lib/Endpoints";
import {
  CartProduct,
  PaystackSuccess,
  Service,
  ServiceDepartments,
} from "../../Lib/Types";
import Cookies from "js-cookie";
import {
  DefaultResponse,
  MakePaymentResponse,
  SynlabGetTokenResponse,
} from "../../Lib/Responses";
import Loader from "../Misc/Loader";
import { notifications } from "@mantine/notifications";
import { useForm } from "@mantine/form";

interface ViewProductProps {
  department?:
    | "dentistry"
    | "ambulance"
    | "diagnostics"
    | "pharmacy"
    | "medical_supplies";
}
export default function ViewProduct({ department }: ViewProductProps) {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { productID } = params;

  const { pathname: pendingRoute } = location;

  const {
    data: product,
    response,
    isLoading: isProductLoading,
  } = usePerformRequest<Service>({
    method: "GET",
    url: `${Endpoints.GetSingleProduct}/${productID}`,
  });

  const [isImageModalOpen, { open: OpenImageModal, close: CloseImageModal }] =
    useDisclosure(false);

  const context = useContext(AppContext);
  const cart = context?.cart.products ? context.cart.products : [];
  const orderForm = useForm({
    initialValues: {
      quantity: 1,
      outletID: "",
    },
    validate: {
      quantity: (value) =>
        value > 0 ? null : "You must purchase at least one",
      outletID: (value) =>
        value && value.length > 0 ? null : "Please select a Pick Up point",
    },
  });

  const orderFormValues = Object.values(orderForm.values);
  const isOrderFormValid =
    parseInt(orderFormValues[0] as string) > 0 &&
    orderFormValues[1]?.toString().length > 0;

  const productInCart = cart.find((p) => p.productId === product?.id);

  const VerifyServicePayment = async (
    reference: string,
    notificationID: string
  ) => {
    setPaymentLoading(true);
    const r: DefaultResponse = await PerformRequest({
      method: "POST",
      route: `${Endpoints.VerifyService}?ref=${reference}`,
      data: {
        id: product?.id,
        name: product?.name,
        department: product?.department,
        provider: { id: product?.vendorId, name: product?.vendorName },
      },
    }).catch(() => {
      setPaymentLoading(false);

      DefaultErrorNotification("Could not complete payment!");
    });
    setPaymentLoading(false);
    if (r && r.data && r.data.statusCode === 201) {
      notifications.update({
        id: notificationID,
        color: "green",
        title: "Payment Verified!",
        message: "Your payment has successfully been verified!",
        loading: false,
        icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,

        autoClose: 2500,
      });
      DefaultSuccessNotification("Payment concluded!");
      setTimeout(() => {
        if (department === "dentistry") {
          window.open("http://calendly.com/yangadentalclinic/book");
        }

        if (
          product &&
          product.vendorId === "6adf8ea9-6f7a-46f5-aea7-b4e55ae37e37"
        ) {
          PerformSynlabHandshake();
        }
        if (department === "ambulance") {
          window.open("http://localhost:3000/dashboard/transactions");
        }
      }, 1500);
    }
  };
  const handleSuccess = (reference: string) => {
    const notificationID = notifications.show({
      loading: true,
      color: "orange",
      withBorder: true,
      title: "Completing service purchase",
      message: "Please DO NOT leave this page! We are verifying your purchase.",
      autoClose: false,
      withCloseButton: false,
    });
    VerifyServicePayment(reference, notificationID);
    // Add Subscription
  };
  let config = {
    reference: "",
    email: context?.profile?.email ?? "",

    publicKey: process.env.REACT_APP_PK_LIVE as string,
    // publicKey: process.env.REACT_APP_PK_TEST as string,

    amount:
      (product?.price ?? 1) *
      100 *
      parseInt(orderForm.values.quantity as any as string),
  };

  const handleClose = () => {
    setPaymentLoading(false);
    DefaultErrorNotification("Transaction was cancelled!");
  };
  const componentProps = {
    ...config,
    text: "Pay now",
    onSuccess: (e: PaystackSuccess) => {
      handleSuccess(e.reference);
    },
    onClose: handleClose,
  };

  const PerformSynlabHandshake = async () => {
    // const r: SynlabGetTokenResponse = await PerformRequest({
    //   method: "POST",
    //   data: {
    //     username: "quickhealth",
    //     password: "r)@_1=bdcg^2nn.1Q",
    //   },
    //   route: "https://lis.synlab.com.ng/SynlabNGPortalAPI/ngapi/GetToken",
    // });
  };

  const [isPaymentLoading, setPaymentLoading] = useState<boolean>(false);

  const StartPayment = async (
    initializePayment: any,
    handleSuccess: any,
    handleClose: any
  ) => {
    setPaymentLoading(true);
    const r = await generatePaymentTransaction({
      amount: config.amount.toString(),
    });
    if (r && r.reference) {
      config = { ...config, reference: r.reference };
      initializePayment(handleSuccess, handleClose);
    }
  };

  const outlets = context
    ? context.outlets
      ? context.outlets
          .filter((o) => o.vendorId === product?.vendorId)
          .map((outlet) => {
            return {
              label: outlet.outletname,
              value: outlet.id,
            };
          })
      : []
    : [];

  return (
    <>
      {isProductLoading ? (
        <Container maxWidth="md">
          <Loader />
        </Container>
      ) : (
        <>
          {context && !isProductLoading && product && (
            <Container maxWidth="md">
              <Modal
                title="Product Images"
                size="75%"
                opened={isImageModalOpen}
                onClose={CloseImageModal}
              >
                <div className="carousel-modal-container">
                  <Carousel
                    height="80vh"
                    className="full-carousel"
                    slideSize="100%"
                    slideGap="lg"
                    loop
                    align="start"
                    withIndicators
                    slidesToScroll={1}
                  >
                    {product.images.map((image, index) => {
                      return (
                        <Carousel.Slide key={index}>
                          <div className="image-container">
                            <img src={image} alt="" className="image" />
                          </div>
                        </Carousel.Slide>
                      );
                    })}
                  </Carousel>
                </div>
              </Modal>
              <div className="view-product-container flex-row width-100 justify-between">
                <div className="carousel">
                  <div className="image-container" onClick={OpenImageModal}>
                    <img src={product.image} alt="" className="image" />
                  </div>
                </div>
                <div className="flex-col product-details">
                  <Text fw={500} className="name capitalize">
                    {product.name}
                  </Text>
                  <Text fw={500} className="price">
                    ₦
                    {getFinancialValueFromNumeric(
                      product.price * (orderForm.values.quantity as number)
                    )}
                  </Text>
                  <Divider w={200} my={5} />
                  <NumberInput
                    w={200}
                    label="Quantity"
                    description="Product quantity"
                    placeholder="Enter product quantity to purchase"
                    min={1}
                    max={99999}
                    {...orderForm.getInputProps("quantity")}
                  />
                  <Select
                    mt={15}
                    data={outlets}
                    {...orderForm.getInputProps("outletID")}
                    placeholder="Select pick up location"
                  />
                  {context.profile ? (
                    <>
                      {["dentistry", "ambulance", "diagnostics"].includes(
                        department as string
                      ) ? (
                        <>
                          {/* @ts-ignore */}
                          <PaystackConsumer {...componentProps}>
                            {({ initializePayment }) => (
                              <Button
                                w={200}
                                my={20}
                                disabled={!isOrderFormValid}
                                rightSection={<IconShoppingCart size={15} />}
                                loading={isPaymentLoading}
                                onClick={() => {
                                  if (context && context.profile?.id) {
                                    StartPayment(
                                      initializePayment,
                                      handleSuccess,
                                      handleClose
                                    );
                                  } else {
                                    navigate("/login");
                                  }
                                }}
                              >
                                Pay Now
                              </Button>
                            )}
                          </PaystackConsumer>
                        </>
                      ) : (
                        <>
                          {productInCart?.quantity &&
                          productInCart.quantity > 0 ? (
                            <div className="flex-row align-center cart-btns">
                              <button
                                className="modify-cart"
                                disabled={context.cart.isCartLoading}
                                onClick={(e) => {
                                  const p: CartProduct = {
                                    productId: product.id,
                                    name: product.name,
                                    image: product.images[0],
                                    price: product.price,
                                    quantity: 1,
                                    department: product.department,

                                    outlet: {
                                      name: "Nett Pharmacy Ikeja",
                                      id: "fa464eab-65f3-4178-96cc-2810d402cd42",
                                    },
                                    vendor: {
                                      id: product.vendorId,
                                      name: product.vendorName,
                                    },
                                    // outlet: { name: "", id: "" },
                                  };
                                  e.preventDefault();

                                  context.cart.addToCart(p);
                                }}
                              >
                                <IconPlus size={20} />
                              </button>
                              &nbsp;
                              <Text
                                fw={500}
                                c="dark"
                                style={{ width: "30px", textAlign: "center" }}
                              >
                                {productInCart.quantity}
                              </Text>
                              &nbsp;
                              <button
                                className="modify-cart"
                                disabled={context.cart.isCartLoading}
                                onClick={(e) => {
                                  e.preventDefault();
                                  const p: CartProduct = {
                                    ...product,
                                    quantity: 1,
                                    productId: product.id,
                                    image: product.images[0],
                                    vendor: { id: product.vendorId, name: "" },
                                    outlet: { name: "", id: "" },
                                  };
                                  context.cart.removeFromCart(p);
                                }}
                              >
                                <IconMinus size={20} />
                              </button>
                            </div>
                          ) : (
                            <Button
                              radius="md"
                              loading={context.cart.isCartLoading}
                              onClick={(e) => {
                                const p: CartProduct = {
                                  productId: product.id,
                                  name: product.name,
                                  image: product.images[0],
                                  price: product.price,
                                  department: product.department,
                                  quantity: 1,
                                  outlet: {
                                    name: "Nett Pharmacy Ikeja",
                                    id: "fa464eab-65f3-4178-96cc-2810d402cd42",
                                  },
                                  vendor: {
                                    id: product.vendorId,
                                    name: product.vendorName,
                                  },
                                  // outlet: { name: "", id: "" },
                                };
                                e.preventDefault();

                                context.cart.addToCart(p);
                              }}
                              className="add-to-cart"
                              rightSection={<IconShoppingCart size={16} />}
                            >
                              Add To Cart
                            </Button>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <Button
                      onClick={() => {
                        navigate("/login");
                        Cookies.set("hasPendingRoute", pendingRoute);
                      }}
                      w={200}
                    >
                      Login to Purchase
                    </Button>
                  )}
                  <Text className="desc">{product.description}</Text>
                  <div className="flex-row items">
                    <Text className="label">Vendor:</Text>
                    {/* <Link
                      to={`/dashboard/vendor/${product.vendorId}`}
                      className="value"
                    > */}
                    {product.vendorName}
                    {/* </Link> */}
                    &nbsp; &nbsp; &nbsp; &nbsp;
                    <Text className="label">Manufacturer:</Text>
                    <Text>{product.manufacturer}</Text>
                  </div>

                  <Text fw={500} my={15}>
                    Tags
                  </Text>
                  <Grid container spacing={1}>
                    {product.tags.map((tag, index) => {
                      return (
                        <Grid key={index} item>
                          <Pill>{tag}</Pill>
                        </Grid>
                      );
                    })}
                  </Grid>
                </div>
              </div>
            </Container>
          )}{" "}
          {isProductLoading || (context === null && <Loader />)}
        </>
      )}
    </>
  );
}
