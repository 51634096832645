import { useContext, useEffect, useState } from "react";

import { Link, useSearchParams } from "react-router-dom";

import { Container, Grid } from "@mui/material";
import { nprogress } from "@mantine/nprogress";

import { Alert, Button, Text, Pagination } from "@mantine/core";

import { ProductCard } from "../ProductCard";

import { AppContext } from "../../Context/AppContext";
import Loader from "../Misc/Loader";
import { usePerformRequest } from "../../Lib/usePerformRequest";
import { PaginationProps, Service } from "../../Lib/Types";
import { Endpoints } from "../../Lib/Endpoints";
import { PaginatedResponse } from "../../Lib/Responses";
import "./styles.scss";

export default function SearchResults() {
  useEffect(() => {
    nprogress.start();
    document.title = "Search Results - Quickhealth";
  }, []);

  useEffect(() => {
    nprogress.complete();
  });

  const context = useContext(AppContext);

  const gridItemProps = {
    xl: 3,
    lg: 3,
    md: 4,
    sm: 6,
    xs: 6,
  };
  const [params, setParams] = useSearchParams();
  const text = params.get("text");
  const department = params.get("department");
  const state = params.get("state");
  const lga = params.get("lga");
  const vendor = params.get("vendor");

  const [productPagination, setProductPagination] = useState<PaginationProps>({
    page: 1,
    limit: 20,
  });

  const {
    data: products,
    response: productsResponse,
    isLoading: isLoadingProducts,
    reloadData: reloadProducts,
  } = usePerformRequest<Service[], PaginatedResponse<Service>>({
    method: "GET",

    url: `${Endpoints.GetAllProducts}/${vendor}?search=${text}&page=${productPagination.page}&limit=${productPagination.limit}&department=${department}&state=${state}&lga=${lga}`,
  });

  useEffect(() => {
    reloadProducts();
  }, [productPagination]);
  return (
    <Container maxWidth="xl" className="department-container">
      <div className="width-100">
        <Text fw={500} size="25px" className="capitalize">
          Search Results
        </Text>
        <br />
        {isLoadingProducts ? (
          <Loader />
        ) : (
          <>
            {!products || products.length === 0 ? (
              <Container maxWidth="sm">
                <Alert title="No results">
                  <Text fz="md" fw={500}>
                    No products were found given your criteria
                  </Text>
                  <div className="flex-row align-center">
                    <Text c="dimmed">Not sure what you need?</Text>
                    &nbsp; &nbsp;
                    <Link to="/dashboard/department/telemedicine">
                      <Button>Speak to a Doctor</Button>
                    </Link>
                  </div>
                </Alert>
              </Container>
            ) : (
              <Grid container rowSpacing={10}>
                {products.map((product, index) => {
                  return (
                    <Grid item {...gridItemProps}>
                      <ProductCard
                        department="medical-supplies"
                        product={product}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            )}
          </>
        )}
      </div>
      <Pagination
        className="pagination"
        my={30}
        onChange={(e) => {
          setProductPagination({ ...productPagination, page: e });
        }}
        total={Math.ceil((productsResponse?.total ?? 100) / 20)}
      />
    </Container>
  );
}
