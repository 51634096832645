import { useContext, useEffect } from "react";

import { Link, useParams, useNavigate } from "react-router-dom";

import { Container } from "@mui/material";
import { nprogress } from "@mantine/nprogress";

import { Button, Text, Paper, Card, Image } from "@mantine/core";
import { IconReportMedical, IconStethoscope } from "@tabler/icons-react";

import { AppContext } from "../../Context/AppContext";

import { ProductCard } from "../ProductCard";

import Dentistry from "../Dentistry";
import AmbulanceServices from "../AmbulanceServices";
import Telemedicine from "../Telemedicine";

import Loader from "../Misc/Loader";
import Diagnostics from "../Diagnostics";
import Pharmacy from "../Pharmacy";
import MedicalSupplies from "../MedicalSupplies";
import ComingSoonImage from "../../Assets/IMG/ComingSoon.jpg";

import "./styles.scss";
export default function Department() {
  const navigate = useNavigate();
  useEffect(() => {
    nprogress.start();
    document.title = "Quickhealth Products";
  }, []);

  useEffect(() => {
    nprogress.complete();
  });
  const context = useContext(AppContext);

  const params = useParams();
  const departmentName = params.departmentName;

  return (
    <Container maxWidth="xl" className="department-container">
      {departmentName !== "telemedicine" && (
        <Paper
          withBorder
          w={300}
          h={150}
          mb={10}
          className="flex-row-important align-center justify-center"
        >
          <IconStethoscope
            stroke={2}
            color="var(--mantine-color-blue-filled)"
            size={50}
          />
          &nbsp;
          <div className="flex-col align-center justify-center">
            <Text fw={500} fz="18px">
              Not sure what you need?
            </Text>
            <Text c="dimmed" my={10}>
              24/7 Telemedicine service
            </Text>
            <Link to="/dashboard/department/telemedicine">
              <Button>Speak to a Doctor</Button>
            </Link>
          </div>
        </Paper>
      )}
      {departmentName === "telemedicine" ? (
        <Telemedicine />
      ) : departmentName === "dentistry" ? (
        <Dentistry />
      ) : departmentName === "diagnostics" ? (
        <Diagnostics />
      ) : departmentName === "medical-supplies" ? (
        <MedicalSupplies />
      ) : departmentName === "pharmacy" ? (
        <Pharmacy />
      ) : departmentName === "ambulance" ? (
        <AmbulanceServices />
      ) : (
        <div className="width-100">
          <Text fw={500} size="25px" className="capitalize">
            {departmentName ? departmentName.replaceAll("-", " ") : ""}
          </Text>
          <br />
          <Container maxWidth="md">
            <Card shadow="sm" padding="xl">
              <Card.Section>
                <Image src={ComingSoonImage} h={300} alt="No way!" />
              </Card.Section>

              <Text fw={600} c="blue" fz="xl" mt="md">
                Coming Soon!
              </Text>

              <Text my="xs" c="dimmed" size="sm">
                This section is in development. Check back later!
              </Text>
              <Button
                my="xs"
                w={200}
                onClick={() => {
                  navigate("/dashboard/department/telemedicine");
                }}
                rightSection={<IconReportMedical />}
              >
                Speak to a doctor
              </Button>
            </Card>
          </Container>
        </div>
      )}
    </Container>
  );
}
