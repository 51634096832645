import React from "react";
import { Link, useParams } from "react-router-dom";

import {
  Text,
  Table,
  Badge,
  Popover,
  Button,
  Alert,
  Divider,
  Paper,
  CopyButton,
} from "@mantine/core";
import { getFinancialValueFromNumeric, getFullDate } from "../../Lib/Methods";
import { IconInfoCircle, IconPrinter } from "@tabler/icons-react";
import { Transaction } from "../../Lib/Types";
import { usePerformRequest } from "../../Lib/usePerformRequest";
import { Endpoints } from "../../Lib/Endpoints";
import { Container } from "@mui/material";
import Loader from "../Misc/Loader";

import Logo from "../../Assets/IMG/Logo.svg";
import "./styles.scss";
import { notifications } from "@mantine/notifications";
import dayjs from "dayjs";
export default function SingleTransaction() {
  const { transactionID } = useParams();

  const {
    data: transaction,
    response: transactionResponse,
    isLoading: isLoadingTransaction,
    reloadData: reloadTransaction,
  } = usePerformRequest<Transaction>({
    method: "GET",

    url: `${Endpoints.GetSingleTransaction}/${transactionID}`,
  });

  const PrintReceipt = () => {
    const element = document.getElementById("do-not-print");
    if (element !== null) {
      element.style.display = "none";
      window.print();
      element.style.display = "flex";
    }
  };
  return (
    <Container className="transactions-container" maxWidth="xl">
      {isLoadingTransaction ? (
        <Loader />
      ) : (
        <>
          {transaction ? (
            <div className="single-transaction-container ">
              <div className="flex-row width-100 align-center justify-end print">
                <Button leftSection={<IconPrinter />} onClick={PrintReceipt}>
                  Print
                </Button>
              </div>
              <div className="receipt">
                <div className="receipt-header flex-col align-center">
                  <img src={Logo} alt="Receipt Logo" />

                  <Text fw={500} fz="30px">
                    Transaction Receipt
                  </Text>
                  <Text c="#1971c2" fw={600} fz="xl">
                    ₦{getFinancialValueFromNumeric(transaction?.amount)}
                  </Text>
                </div>
                <Table
                  className="table"
                  verticalSpacing="md"
                  withRowBorders={false}
                >
                  <Table.Tbody>
                    <Table.Tr>
                      <Table.Td>
                        <Text className="label">Date</Text>
                      </Table.Td>
                      <Table.Td>
                        <Text className="value">
                          {dayjs(transaction.createdAt).format(
                            "dddd, DD MMM YYYY"
                          )}
                        </Text>
                      </Table.Td>
                    </Table.Tr>

                    <Table.Tr>
                      <Table.Td>
                        <Text className="label">Reference</Text>
                      </Table.Td>
                      <Table.Td>
                        <Text className="value uppercase">
                          {transaction?.reference}
                        </Text>
                      </Table.Td>
                    </Table.Tr>

                    <Table.Tr>
                      <Table.Td>
                        <Text className="label">Quickhealth Token</Text>
                      </Table.Td>
                      <Table.Td>
                        <div className="flex-row align-center">
                          <Text className="value uppercase">
                            {transaction?.token}
                          </Text>
                          &nbsp;
                          <Popover
                            width={250}
                            position="bottom"
                            withArrow
                            shadow="md"
                          >
                            <Popover.Target>
                              <IconInfoCircle color="#1971c2" size={20} />
                            </Popover.Target>
                            <Popover.Dropdown>
                              <Text size="sm">
                                This token might be required by health providers
                                to verify your purchase
                              </Text>
                            </Popover.Dropdown>
                          </Popover>
                        </div>
                      </Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td>
                        <Text className="label">Status</Text>
                      </Table.Td>
                      <Table.Td>
                        <Badge className="value" color="green">
                          <Text fz="15px" fw={500} className="capitalize">
                            Success
                          </Text>
                        </Badge>
                      </Table.Td>
                    </Table.Tr>
                  </Table.Tbody>
                </Table>
                <Text fw={600}>Products</Text>

                {transaction?.offerings?.map((offering) => {
                  return (
                    <Paper withBorder my={10}>
                      <Table
                        className="table"
                        verticalSpacing="md"
                        withRowBorders={false}
                      >
                        <Table.Tbody>
                          <Table.Tr>
                            <Table.Td>
                              <Text className="label">Product Name</Text>
                            </Table.Td>
                            <Table.Td>
                              <Text className="capitalize">
                                {offering.name}
                              </Text>
                            </Table.Td>
                          </Table.Tr>
                          <Table.Tr>
                            <Table.Td>
                              <Text className="label">Vendor</Text>
                            </Table.Td>
                            <Table.Td>
                              <Text className="capitalize">
                                {offering.provider.name}
                              </Text>
                            </Table.Td>
                          </Table.Tr>
                          {offering.department === "telemedicine" && (
                            <>
                              <Table.Tr>
                                <Table.Td>
                                  <Text className="label">Phone Number</Text>
                                </Table.Td>
                                <Table.Td>
                                  <CopyButton value="08000432584">
                                    {({ copied, copy }) => (
                                      <Text
                                        c="blue"
                                        fw={600}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          copy();
                                          notifications.show({
                                            color: "green",
                                            message: "Copied Phone number!",
                                          });
                                        }}
                                      >
                                        08000432584
                                      </Text>
                                    )}
                                  </CopyButton>
                                </Table.Td>
                              </Table.Tr>
                              <Table.Tr>
                                <Table.Td>
                                  <Text className="label">Expiry Date</Text>
                                </Table.Td>
                                <Table.Td>
                                  <Text
                                    c={
                                      dayjs().diff(
                                        dayjs(transaction?.createdAt),
                                        "day"
                                      ) > 30
                                        ? "red"
                                        : "#00802b  "
                                    }
                                  >
                                    {getFullDate(
                                      dayjs(transaction?.createdAt).add(
                                        30,
                                        "day"
                                      )
                                    )}
                                  </Text>
                                </Table.Td>
                              </Table.Tr>
                            </>
                          )}
                        </Table.Tbody>
                      </Table>
                    </Paper>
                  );
                })}
                <div className="thank-you">
                  <p>Thank you for your purchase!</p>
                </div>
              </div>
            </div>
          ) : (
            <Container maxWidth="sm">
              <Alert title="Nope">
                <Text fz="md" fw={500}>
                  This transaction does not exist!
                </Text>
                <div className="flex-row align-center">
                  <Link to="/dashboard/department/telemedicine">
                    <Button>Transactions</Button>
                  </Link>
                </div>
              </Alert>
            </Container>
          )}
        </>
      )}
    </Container>
  );
}
