import { useContext, useState } from "react";

import { Link, useParams } from "react-router-dom";

import {
  Card,
  Group,
  Text,
  Badge,
  Paper,
  Button,
  Tooltip,
} from "@mantine/core";
import { IconMinus, IconPlus } from "@tabler/icons-react";
import { getFinancialValueFromNumeric } from "../../Lib/Methods";

import classes from "./ProductCard.module.css";
import { AppContext } from "../../Context/AppContext";
import { CartProduct, Service } from "../../Lib/Types";

interface ProductProps {
  product: Service;
  department?:
    | "dentistry"
    | "ambulance"
    | "diagnostics"
    | "pharmacy"
    | "medical-supplies";
}

const getProductDescription = (description: string) => {
  if (description) {
    return description.length > 80
      ? `${description.substring(0, 75)}...`
      : description;
  } else {
    return "**No Description**";
  }
};
export function ProductCard({ product, department }: ProductProps) {
  const context = useContext(AppContext);
  const params = useParams();
  const cart = context?.cart.products ? context.cart.products : [];

  const productInCart = cart.find((p) => p.name === product.name);
  const [isHoverActive, setHoverActive] = useState<boolean>(false);

  const getProductName = () => {
    const { name } = product;
    return name.length > 30 ? `${name.substring(0, 30)}...` : name;
  };
  return (
    <>
      {context && (
        <Link
          to={`/dashboard/${department ? department : "product"}/${product.id}`}
        >
          <Paper
            shadow={isHoverActive ? "md" : "none"}
            onMouseOver={() => {
              setHoverActive(true);
            }}
            onClick={() => {
              setHoverActive(true);
            }}
            onBlur={() => {
              setHoverActive(false);
            }}
            onMouseOut={() => {
              setHoverActive(false);
            }}
            style={{
              width: "fit-content",
              cursor: "pointer",
            }}
          >
            <Card radius="md" className={classes.card}>
              <Card.Section className={classes.imageSection}>
                <img src={product.image} className={classes.image} />
              </Card.Section>

              <Group justify="space-between" mt="md">
                <div className={classes.productBodyDesc}>
                  <Tooltip label={product.name}>
                    <Text fw={500} fz="15px" className="capitalize">
                      {getProductName()}
                    </Text>
                  </Tooltip>
                  <Badge
                    variant="transparent"
                    color="teal"
                    radius="xs"
                    pl={0}
                    pr={0}
                  >
                    {product.manufacturer}
                  </Badge>
                  <Text fz="sm" c="dark">
                    {getProductDescription(product.description)}
                  </Text>
                </div>
              </Group>

              <Card.Section className={classes.section}>
                <div className="flex-row width-100 align-center justify-between">
                  <div>
                    <Text fz="xl" fw={600} style={{ lineHeight: 1 }}>
                      ₦{getFinancialValueFromNumeric(product.price)}
                    </Text>
                  </div>

                  {!department && (
                    <>
                      {productInCart ? (
                        <div className="flex-row align-center">
                          <button
                            className={classes.ModifyCartButton}
                            onClick={(e) => {
                              const p: CartProduct = {
                                ...product,
                                quantity: 1,
                                productId: product.id,
                                image: product.images[0],
                                vendor: { id: product.vendorId, name: "" },
                                outlet: { name: "", id: "" },
                              };
                              e.preventDefault();
                              context.cart.addToCart(p);
                            }}
                          >
                            <IconPlus size="2rem" />
                          </button>
                          &nbsp;
                          <Text
                            fw={500}
                            color="dark"
                            style={{ width: "30px", textAlign: "center" }}
                          >
                            {productInCart.quantity}
                          </Text>
                          &nbsp;
                          <button
                            className={classes.ModifyCartButton}
                            onClick={(e) => {
                              e.preventDefault();
                              const p: CartProduct = {
                                ...product,
                                quantity: 1,
                                productId: product.id,
                                image: product.images[0],
                                vendor: { id: product.vendorId, name: "" },
                                outlet: { name: "", id: "" },
                              };
                              context.cart.removeFromCart(p);
                            }}
                          >
                            <IconMinus size="2rem" />
                          </button>
                        </div>
                      ) : (
                        <Button
                          radius="xl"
                          className={classes.AddToCart}
                          onClick={(e) => {
                            const p: CartProduct = {
                              ...product,
                              quantity: 1,
                              productId: product.id,
                              image: product.images[0],
                              vendor: { id: product.vendorId, name: "" },
                              outlet: { name: "", id: "" },
                            };
                            e.preventDefault();
                            context.cart.addToCart(p);
                          }}
                        >
                          Add
                        </Button>
                      )}
                    </>
                  )}
                </div>
                {/* <div className="flex-row align-center">
                  <Text c="#1971c2" fz="sm">
                    <IconMapPinFilled size={12} color="#1971c2" />
                  </Text>
                  &nbsp;
                  <Text fw={500} c="#1971c2" fz="sm">
                    GRA, Lagos
                  </Text>
                </div> */}
              </Card.Section>
            </Card>
          </Paper>
        </Link>
      )}
    </>
  );
}
