import { useContext, useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import { Container, Grid } from "@mui/material";
import { nprogress } from "@mantine/nprogress";

import { Text, Pagination } from "@mantine/core";

import { ProductCard } from "../ProductCard";

import { AppContext } from "../../Context/AppContext";
import Loader from "../Misc/Loader";
import { usePerformRequest } from "../../Lib/usePerformRequest";
import { PaginationProps, Service } from "../../Lib/Types";
import { Endpoints } from "../../Lib/Endpoints";
import { PaginatedResponse } from "../../Lib/Responses";

import "./styles.scss";

export default function MedicalSupplies() {
  const context = useContext(AppContext);
  useEffect(() => {
    nprogress.start();
    document.title = "Quickhealth - Pushmec";
  }, []);

  useEffect(() => {
    nprogress.complete();
  });

  const [productPagination, setProductPagination] = useState<PaginationProps>({
    page: 1,
    limit: 20,
  });

  const {
    data: products,
    response: productsResponse,
    isLoading: isLoadingProducts,
    reloadData: reloadProducts,
  } = usePerformRequest<Service[], PaginatedResponse<Service>>({
    method: "GET",

    url: `${Endpoints.GetProducts}?page=${productPagination.page}&limit=${productPagination.limit}&department=medical_supplies`,
  });

  useEffect(() => {
    reloadProducts();
  }, [productPagination]);
  return (
    <Container maxWidth="xl" className="department-container">
      <div className="width-100">
        <Text fw={500} size="25px" className="capitalize">
          Medical Supplies
        </Text>
        <br />
        {products && !isLoadingProducts ? (
          <Grid
            container
            rowSpacing={10}
            columnSpacing={10}
            alignItems="center"
            justifyContent="center"
          >
            {products.map((product, index) => {
              return (
                <Grid item>
                  <ProductCard
                    department="medical-supplies"
                    product={product}
                  />
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <Loader />
        )}
      </div>
      <Pagination
        className="pagination"
        my={30}
        onChange={(e) => {
          setProductPagination({ ...productPagination, page: e });
        }}
        total={Math.ceil((productsResponse?.total ?? 100) / 20)}
      />
    </Container>
  );
}
