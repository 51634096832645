import { Link } from "react-router-dom";

import {
  Paper,
  Container,
  Title,
  Button,
  Group,
  Text,
  List,
  ThemeIcon,
  rem,
} from "@mantine/core";
import { Carousel } from "@mantine/carousel";
import { IconCheck } from "@tabler/icons-react";
import HealthConnectBanner from "../../Assets/IMG/HealthConnectBanner.png";
import PharmacyImage from "../../Assets/IMG/Departments/Pharmacy.jpg";
import MedicalSuppliesImage from "../../Assets/IMG/Departments/MedicalSupplies.jpg";
import DiagnosticsImage from "../../Assets/IMG/Departments/Diagnostics.jpg";
import PhysiotherapyImage from "../../Assets/IMG/Departments/Physiotherapy.jpg";
import AmbulanceImage from "../../Assets/IMG/Departments/Ambulance.jpg";
import DentistryImage from "../../Assets/IMG/Departments/Dentistry.jpg";

import "./styles.scss";

interface DepartmentOveriew {
  name: string;
  image: string;
  description: string;
}
const departments: DepartmentOveriew[] = [
  {
    name: "Pharmacy",
    image: PharmacyImage,
    description: "Shop a wide variety of Pharmaceutical products",
  },
  {
    name: "Diagnostics",
    image: DiagnosticsImage,
    description: "Diagnostics tests available on order",
  },
  {
    name: "Dentistry",
    image: DentistryImage,
    description: "Register for dental appointments",
  },
  {
    name: "Medical Supplies",
    image: MedicalSuppliesImage,
    description: "Purchase Medical supplies and equipment",
  },
  {
    name: "Physiotherapy",
    image: PhysiotherapyImage,
    description: "Book a professional physiotherapist near you",
  },
  {
    name: "Ambulance",
    image: AmbulanceImage,
    description: "Request the service of an Ambulance immediately!",
  },
];
export default function Overview() {
  return (
    <Container size="md" className="overview-container">
      <div className="inner">
        <div className="content">
          <Title className="title">
            Speak to a&nbsp;<span className="highlight">Doctor</span>
          </Title>
          <Text mt="md">
            We have qualified medical personnel standing by round the clock to
            attend to you <br />
            Contact us Now!
          </Text>

          <Group mt={30}>
            <Link to="/dashboard/department/telemedicine">
              <Button radius="xl" size="md" className="control">
                Speak to a Doctor
              </Button>
            </Link>
          </Group>
        </div>
        <img src={HealthConnectBanner} className="image" />
      </div>
      <Text mt={60} mb={20} fw={500} fz="xl">
        Quick Access
      </Text>
      <Carousel
        height={320}
        slideSize="33.333333%"
        slideGap="md"
        loop
        controlsOffset={-100}
        align="start"
        slidesToScroll={1}
      >
        {departments.map((department, index) => {
          return (
            <Carousel.Slide key={index}>
              <div className="flex-col department-slide">
                <img src={department.image} alt="" className="image" />
                <div className="content flex-col width-100">
                  <Text className="name" fw={500}>
                    {department.name}
                  </Text>
                  <Text className="description" c="gray">
                    {department.description}
                  </Text>
                </div>
                <Link
                  to={`/dashboard/department/${department.name
                    .replaceAll(" ", "-")
                    .toLowerCase()}`}
                >
                  <Button variant="subtle" className="btn">
                    View
                  </Button>
                </Link>
              </div>
            </Carousel.Slide>
          );
        })}
      </Carousel>
      <br />
      <br />
    </Container>
  );
}
